<template>
  <div class="orderHistory">
    <Header />
    <pageTitle txt="請求情報" :backBtn="backBtn" />
    <div class="orderHistory-thisMonth">
      <span class="orderHistory-thisMonth__ttl">今月の請求</span>
      <dl class="orderHistory-item">
        <dt class="orderHistory-item__ttl">請求金額</dt>
        <dd class="orderHistory-item__value">{{ target.total_price | comma }}円</dd>
        <dt class="orderHistory-item__ttl">送料</dt>
        <dd class="orderHistory-item__value">{{ target.send_price | comma }}円</dd>
        <dt class="orderHistory-item__ttl">割引額</dt>
        <dd class="orderHistory-item__value negative">{{ target.service_price | comma }}円</dd>
        <dt class="orderHistory-item__ttl">消費税</dt>
        <dd class="orderHistory-item__value">{{ target.tax_price | comma }}円</dd>
      </dl>
      <div class="orderHistory__total">
        <span class="orderHistory__total-price">
          合計
          <span class="orderHistory__total-num">{{ target.total_tax_price | comma }}</span>
          円
        </span>
      </div>
    </div>
    <div class="orderHistory-past">
      <span class="orderHistory-past__ttl">過去の請求履歴</span>
      <div class="orderHistory-past__item" v-for="(row, key) in list" :key="key">
        <span class="orderHistory-past__label">
          {{ row.ym }}
          <span class="total">（{{ row.total_tax_price | comma }}円）</span>
          <span @click="pastOpen" class="close">閉じる</span>
          <span @click="pastOpen" class="open">開く</span>
        </span>
        <dl class="orderHistory-item">
          <dt class="orderHistory-item__ttl">請求金額</dt>
          <dd class="orderHistory-item__value">{{ row.total_price | comma }}円</dd>
          <dt class="orderHistory-item__ttl">送料</dt>
          <dd class="orderHistory-item__value">{{ row.send_price | comma }}円</dd>
          <dt class="orderHistory-item__ttl">割引額</dt>
          <dd class="orderHistory-item__value negative">{{ row.service_price | comma }}円</dd>
          <dt class="orderHistory-item__ttl">消費税</dt>
          <dd class="orderHistory-item__value">{{ row.tax_price | comma }}円</dd>
        </dl>
        <div class="orderHistory__total">
          <span class="orderHistory__total-price">
            合計
            <span class="orderHistory__total-num">
              {{ row.total_tax_price | comma }}
            </span>
            円
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/shop/header";
import pageTitle from "@/components/shop/pageTitle";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "orderHistory",
  components: {
    Header,
    pageTitle
  },
  data: function() {
    return {
      backBtn: true,
      list: [],
      target: {}
    };
  },
  mixins: [Global],
  async created() {
    const api = new ApiClient();
    const result = await api.get(`/store/payment/list`);
    this.list = result.list;
    this.target = result.target;
  },
  methods: {
    pastOpen(e) {
      let parent = e.currentTarget.closest(".orderHistory-past__item");
      parent.classList.toggle("is-open");
    }
  }
};
</script>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.orderHistory-thisMonth__ttl {
  @extend .f_pageTtl;
  display: block;
  box-sizing: border-box;
  padding: 20px 30px;
}
.orderHistory-item {
  display: flex;
  flex-wrap: wrap;
}
.orderHistory-item__ttl,
.orderHistory-item__value {
  width: 50%;
  height: 40px;
  border-bottom: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 30px;
  display: flex;
  align-items: center;
  @extend .f_body;
}
.orderHistory-item__value {
  justify-content: flex-end;
  &.negative {
    color: variables.$negative;
  }
}
.orderHistory__total {
  background: variables.$bg-gray-2;
  height: 60px;
  display: flex;
  align-items: center;
}
.orderHistory__total-price {
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  @extend .f_body;
  text-align: right;
}
.orderHistory__total-num {
  @extend .f_number;
  margin-left: 13px;
  margin-right: 1px;
}
.orderHistory-past__ttl {
  @extend .f_pageTtl;
  display: block;
  box-sizing: border-box;
  padding: 60px 30px 20px 30px;
  text-align: center;
}
.orderHistory-past__item {
  .total {
    opacity: 1;
  }
  .open {
    display: block;
  }
  .close,
  .orderHistory__total {
    display: none;
  }
  .orderHistory-item {
    height: 0;
    overflow: hidden;
  }
  &.is-open {
    .orderHistory-item {
      height: auto;
    }
    .total {
      opacity: 0;
    }
    .open {
      display: none;
    }
    .close {
      display: block;
    }
    .orderHistory__total {
      display: flex;
    }
  }
}
.orderHistory-past__label {
  @extend .f_body;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0 30px;
  position: relative;
  .total {
    color: variables.$txt-pale;
    margin-left: 5px;
  }
  .open,
  .close {
    @extend .f_caption;
    color: variables.$scrollbar-back;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
</style>
