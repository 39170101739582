<template>
  <div class="address">
    <Header @change_shop="changeShop" />
    <pageTitle txt="納品先登録" :backBtn="backBtn" />
    <div class="address-input">
      <span v-if="id == ''" class="address-input__label">新規納品先登録</span>
      <span v-if="id != ''" class="address-input__label">納品先変更</span>
      <input type="text" placeholder="納品先の名称" v-model="name" />
      <div class="error">{{ errors.name }}</div>
      <input
        type="text"
        placeholder="郵便番号"
        max-length="8"
        v-model="zipcode"
        @change="searchZipCode"
      />
      <div class="error">{{ errors.zipcode }}</div>
      <textarea placeholder="住所" v-model="address" />
      <div class="error">{{ errors.address }}</div>
      <btnS
        class="shop-list__item"
        btnTxt="登録する"
        color="positive1"
        @click="goSave"
      />
      <btnS
        v-if="id != ''"
        class="shop-list__item"
        btnTxt="削除する"
        color="positive1"
        @click="goDelete"
      />
    </div>
    <span class="shop-list__label" v-if="deliveries.length > 0">
      納品先一覧
    </span>
    <ul class="shop-list">
      <li
        class="shop-list__item"
        v-for="(delivery, key) in deliveries"
        :key="key"
        @click="edit(delivery)"
      >
        {{ delivery.name }}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.address-input {
  box-sizing: border-box;
  padding: 40px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.address-input__label {
  width: 100%;
  display: block;
  @extend .f_pageTtl;
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: center;
}
input[type="text"],
input[type="number"],
textarea {
  width: 100%;
  border-radius: 3px;
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  border: 1px solid #ededed;
  box-sizing: border-box;
  @include mixin.placeholderColor(#cbcbcb);
  margin-bottom: 10px;
  transform: scale(0.81);
  font-size: 1.6rem;
}
input[type="text"],
input[type="number"] {
  height: 40px;
  padding: 0 15px;
}
textarea {
  height: 100px;
  padding: 10px 15px;
}
.shop-list__label {
  width: 100%;
  display: block;
  @extend .f_pageTtl;
  height: 40px;
  margin-bottom: 20px;
  box-sizing: border-box;
  text-align: center;
}
.shop-list {
  padding: 0 30px 20px;
  width: 100%;
  box-sizing: border-box;
}
.shop-list__item {
  margin-bottom: 20px;
  height: 40px;
  border-bottom: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  cursor: pointer;
  @extend .f_body;
}
</style>

<script>
// @ is an alias to /src
import Header from "@/components/shop/header";
import pageTitle from "@/components/shop/pageTitle";
import btnS from "@/components/btn/btnS";
import ApiClient from "@/module/api";

export default {
  name: "ShopAddress",
  components: {
    Header,
    pageTitle,
    btnS
  },
  data: function() {
    return {
      backBtn: true,
      deliveries: [],
      id: "",
      name: "",
      zipcode: "",
      address: "",
      errors: {}
    };
  },
  async created() {
    this._redraw();
  },
  methods: {
    changeShop() {
      this._redraw();
    },
    async goSave() {
      const loginData = await this.$store.getters[`getStoreLoginData`];
      const param = {
        store_id: loginData.shop_id,
        name: this.name,
        zipcode: this.zipcode,
        address: this.address
      };
      if (this.id !== "") {
        param.id = this.id;
      }
      const api = new ApiClient();
      const result = await api.post("/store/delivery/save", param);
      const errorResult = api.getErrors(result);
      if (errorResult.isHit) {
        this.errors = errorResult.errors;
      } else {
        this.clearInputData();
        this._redraw();
      }
    },
    edit(row) {
      this.id = row.id;
      this.name = row.name;
      this.zipcode = row.zipcode;
      this.address = row.address;
    },
    async searchZipCode() {
      const api = new ApiClient();
      let address = await api.searchZip(this.zipcode);
      address = address[0];
      this.address = address.address1 + address.address2 + address.address3;
    },
    async goDelete() {
      if (confirm("この納品先を削除しますか？")) {
        const param = {
          id: this.id
        };
        const api = new ApiClient();
        await api.post(`/store/delivery/delete`, param);
        this.clearInputData();
        this._redraw();
      }
    },
    async _redraw() {
      const loginData = await this.$store.getters[`getStoreLoginData`];
      const param = {
        store_id: loginData.shop_id
      };
      const api = new ApiClient();
      const result = await api.post(`/store/delivery/list`, param);
      this.deliveries = result.list;
    },
    clearInputData() {
      this.id = "";
      this.name = "";
      this.zipcode = "";
      this.address = "";
    }
  }
};
</script>
