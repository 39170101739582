<template>
  <div>
    <Header />
    <pageTitle txt="商品別売上日報" :backBtn="backBtn" />
    <div id="meta">
      <div class="meta-date">{{ date | dateFormat }}</div>
      <div class="meta-calendar">
        <Datepicker
          ref="datepicker"
          :monday-first="true"
          :language="ja"
          @selected="changePicker"
          wrapper-class="meta-calendar__wrapper"
          input-class="meta-calendar__input"
          placeholder=""
          format="yyyy年M月d日 D曜日"
        />
        <div class="meta-calendar__btn">
          <span class="icon">
            <img src="../../../assets/icon/calendar.svg" />
          </span>
          カレンダーで日付選択
        </div>
      </div>
      <div class="meta-btn">
        <div class="meta-btn__prev" @click="beforeDate">前の日</div>
        <div>
          <btnS btnTxt="今日" color="neutral" @click="setToday" />
        </div>
        <div class="meta-btn__next" @click="nextDate">次の日</div>
      </div>
      <div v-on:click="print = !print" class="print-btn">
        <btnS class="print" btnTxt="印刷" color="primary" />
      </div>
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <div class="list-head__item col-name">商品名</div>
          <div class="list-head__item col-unit">単位</div>
          <div class="list-head__item col-quantity">総売上数量</div>
          <div class="list-head__item col-sales">総売上高</div>
        </li>
        <li class="list-subhead">
          <div class="list-head__item col-name"></div>
          <div class="list-head__item col-unit"></div>
          <div class="list-head__item col-quantity">
            <div class="list-head__item-label">当日</div>
            <div class="list-head__item-label border">当月</div>
          </div>
          <div class="list-head__item col-sales">
            <div class="list-head__item-label">当日</div>
            <div class="list-head__item-label border">当月</div>
          </div>
        </li>
        <li class="list-item" v-for="product in list" :key="product.item_id">
          <div class="list-item__data col-name">{{ product.name }}</div>
          <div class="list-item__data col-unit">{{ product.unit }}</div>
          <div class="list-item__data col-half">
            {{ product.total_num | comma }}
          </div>
          <div class="list-item__data col-half border">
            {{ product.total_price | comma }}
          </div>
          <div class="list-item__data col-half">
            {{ product.month_total_num | comma }}
          </div>
          <div class="list-item__data col-half border">
            {{ product.month_total_price | comma }}
          </div>
        </li>
        <li class="list-item total">
          <div class="list-item__data col-name">小 計</div>
          <div class="list-item__data col-unit"></div>
          <div class="list-item__data col-half">
            {{ total.total_num | comma }}
          </div>
          <div class="list-item__data col-half border">
            {{ total.total_price | comma }}
          </div>
          <div class="list-item__data col-half">
            {{ total.month_total_num | comma }}
          </div>
          <div class="list-item__data col-half border">
            {{ total.month_total_price | comma }}
          </div>
        </li>
        <!-- <li class="list-item total" >
          <div class="list-item__data col-name">その他の商品（29点）</div>
          <div class="list-item__data col-unit"></div>
          <div class="list-item__data col-half">200.0</div>
          <div class="list-item__data col-half border">6,540.0</div>
          <div class="list-item__data col-half">42,000</div>
          <div class="list-item__data col-half border">1,373,400</div>
        </li> -->
        <li class="list-item total">
          <div class="list-item__data col-name">合 計</div>
          <div class="list-item__data col-unit"></div>
          <div class="list-item__data col-half">
            {{ total.total_num | comma }}
          </div>
          <div class="list-item__data col-half border">
            {{ total.total_price | comma }}
          </div>
          <div class="list-item__data col-half">
            {{ total.month_total_num | comma }}
          </div>
          <div class="list-item__data col-half border">
            {{ total.month_total_price | comma }}
          </div>
        </li>
      </ul>
    </div>

    <div id="overlay" :class="{ visible: print }">
      <div id="print">
        <div id="print-meta">
          <p class="print-ttl">商品別売上日報</p>
          <div class="print-sig">
            <div class="print-sig__item"></div>
            <div class="print-sig__item"></div>
            <div class="print-sig__item"></div>
          </div>
          <p class="print-period">{{ date | dateFormat }}</p>
          <div class="print-time">
            {{ this.today }} 現在
            <br />
            1/1頁
          </div>
        </div>
        <ul class="list">
          <li class="list-head">
            <div class="list-head__item col-name">商品名</div>
            <div class="list-head__item col-unit">単位</div>
            <div class="list-head__item col-quantity">総売上数量</div>
            <div class="list-head__item col-sales">総売上高</div>
          </li>
          <li class="list-subhead">
            <div class="list-head__item col-name"></div>
            <div class="list-head__item col-unit"></div>
            <div class="list-head__item col-quantity">
              <div class="list-head__item-label">当日</div>
              <div class="list-head__item-label border">当月</div>
            </div>
            <div class="list-head__item col-sales">
              <div class="list-head__item-label">当日</div>
              <div class="list-head__item-label border">当月</div>
            </div>
          </li>
          <li class="list-item" v-for="product in list" :key="product.item_id">
            <div class="list-item__data col-name">{{ product.name }}</div>
            <div class="list-item__data col-unit">{{ product.unit }}</div>
            <div class="list-item__data col-half">
              {{ product.total_num | comma }}
            </div>
            <div class="list-item__data col-half border">
              {{ product.total_price | comma }}
            </div>
            <div class="list-item__data col-half">
              {{ product.month_total_num | comma }}
            </div>
            <div class="list-item__data col-half border">
              {{ product.month_total_price | comma }}
            </div>
          </li>
          <li class="list-item total">
            <div class="list-item__data col-name">小 計</div>
            <div class="list-item__data col-unit"></div>
            <div class="list-item__data col-half">
              {{ total.total_num | comma }}
            </div>
            <div class="list-item__data col-half border">
              {{ total.total_price | comma }}
            </div>
            <div class="list-item__data col-half">
              {{ total.month_total_num | comma }}
            </div>
            <div class="list-item__data col-half border">
              {{ total.month_total_price | comma }}
            </div>
          </li>
          <li class="list-item total">
            <div class="list-item__data col-name">合 計</div>
            <div class="list-item__data col-unit"></div>
            <div class="list-item__data col-half">
              {{ total.total_num | comma }}
            </div>
            <div class="list-item__data col-half border">
              {{ total.total_price | comma }}
            </div>
            <div class="list-item__data col-half">
              {{ total.month_total_num | comma }}
            </div>
            <div class="list-item__data col-half border">
              {{ total.month_total_price | comma }}
            </div>
          </li>
        </ul>
      </div>
      <div id="fixedMenu">
        <div v-on:click="print = !print">
          <btnS btnTxt="戻る" color="back" />
        </div>
        <div v-on:click="print = !print">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

#meta {
  width: 100%;
  height: 75px;
  box-sizing: border-box;
  border-bottom: solid 1px variables.$bg-gray-2;

  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  position: relative;
}

.meta-date {
  @extend .f_pageTtl;
  margin-right: 60px;
}

.meta-calendar {
  position: relative;
  width: 165px;
}

.meta-calendar__btn {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  @extend .f_body;
  pointer-events: none;
}

.icon {
  margin-right: 7px;
  display: inline-block;
  width: 17px;
  height: 16px;
  position: relative;
  top: 1px;
  img {
    width: 100%;
  }
}

.meta-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  .buttonS {
    margin: 0 30px;
  }
}

.meta-btn__prev,
.meta-btn__next {
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  cursor: pointer;
}

.meta-btn__prev {
  &:before {
    content: "";
    width: 5px;
    height: 9px;
    background: {
      image: url(../../../assets/icon/arrow-left-1.svg);
      size: contain;
    }
    display: inline-block;
    margin-right: 15px;
  }
}

.meta-btn__next {
  &:after {
    content: "";
    width: 5px;
    height: 9px;
    background: {
      image: url(../../../assets/icon/arrow-right-1.svg);
      size: contain;
    }
    display: inline-block;
    margin-left: 15px;
  }
}

.print-btn {
  margin-left: auto;
}
#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  @extend .f_body;
  display: flex;
  flex-wrap: wrap;
  width: 631px;
  margin: 0 auto;
  border-left: solid 1px variables.$bg-gray-2;
}

.list-head {
  display: flex;
  height: 60px;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
}

.list-subhead {
  display: flex;
  height: 25px;
  font-size: 1.2rem;
  letter-spacing: 0em;
  box-sizing: border-box;
  border-bottom: solid 1px variables.$bg-gray-2;
}

.list-head__item {
  height: 100%;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.list-head__item-label {
  width: 90px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.border {
  background-color: #ededed;
  background-image: repeating-linear-gradient(
    45deg,
    #fff,
    #fff 2px,
    transparent 0,
    transparent 4px
  );
}

.list-item {
  display: flex;
  flex-wrap: wrap;
  height: 40px;
  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
  &.total {
    &:nth-of-type(even) {
      background: none;
    }
  }
}

.list-item__data {
  box-sizing: border-box;
  padding: 0 15px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  display: flex;
  align-items: center;
}

.col-name {
  width: 200px;
}

.col-unit {
  width: 70px;
  justify-content: center;
}

.col-quantity,
.col-sales {
  width: 180px;
}

.col-half {
  width: 90px;
  justify-content: flex-end;
}

#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
#print {
  width: 945px;
  height: 668px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px;

  position: absolute;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0);
  .list {
    transform: scale(0.95);
    transform-origin: top center;
  }
}

#print-meta {
  margin-bottom: 20px;
}

.print-time {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: right;
}

.print-sig {
  display: flex;
  position: absolute;
  top: 30px;
  right: 160px;
  border-left: solid 1px variables.$txt-default;
}

.print-sig__item {
  box-sizing: border-box;
  width: 55px;
  height: 50px;
  border-right: solid 1px variables.$txt-default;
  border-top: solid 1px variables.$txt-default;
  border-bottom: solid 1px variables.$txt-default;
  position: relative;
  &:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    left: 11px;
    top: 0;
    background: variables.$txt-default;
  }
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 30px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}
</style>
<style lang="scss">
.meta-calendar__input {
  color: rgba(255, 255, 255, 0);
}

@media print {
  @page {
    size: landscape;
    margin: 0;
  }
  #wrapper,
  #fixedMenu {
    display: none !important;
  }
  #print {
    width: 251mm;
    height: 172mm;
    page-break-after: always;
    .list {
      transform: scale(1);
    }
  }
}
</style>
<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import Datepicker from "vuejs-datepicker";
import { ja } from "vuejs-datepicker/dist/locale";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    Datepicker,
    btnS,
    btnL
  },
  data: function() {
    return {
      backBtn: true,
      print: false,
      ja: ja,
      today: "",
      date: "",
      list: [],
      total: {}
    };
  },
  mixins: [Global],

  async created() {
    this.date = new Date();
    const y = this.date.getFullYear();
    const m = this.date.getMonth() + 1;
    const d = this.date.getDate();
    this.today = y + "年" + m + "月" + d + "日";
    this._read();
  },

  methods: {
    setToday() {
      this.date = new Date();
      this._read();
    },
    beforeDate() {
      this.date.setDate(this.date.getDate() - 1);
      this._read();
    },
    nextDate() {
      this.date.setDate(this.date.getDate() + 1);
      this._read();
    },
    changePicker(date) {
      this.date = date;
      this._read();
    },
    async _read() {
      const y = this.date.getFullYear();
      const m = this.date.getMonth() + 1;
      const d = this.date.getDate();

      const param = {
        date: y + ("0" + m).slice(-2) + ("0" + d).slice(-2)
      };
      const api = new ApiClient();
      const result = await api.post(`/iseya/sale/item`, param);
      this.list = result.list;
      this.total = result.total;
    }
  }
};
</script>
