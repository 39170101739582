<template>
  <div class="home">
    <Header />
    <pageTitle txt="ホーム" :backBtn="backBtn" />
    <div class="news-blk">
      <div class="news-blk__inner">
        <span class="news-title">お知らせ</span>
        <ul class="news-list">
          <li class="news-list__item" v-for="(info, key) in infos" :key="key">
            <span class="news-date">{{ info.created }}</span>
            <p class="news-body">
              {{ info.body }}
              <!-- <a
                href=""
                class="news-link"
                >リンクがある場合</a
              > -->
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="home-blk">
      <div class="home-blk__inner">
        <div class="home-btns">
          <router-link to="/shop/shipment/">
            <btnM
              class="home-btns__item"
              btnTxt="荷物番号確認"
              color="neutral"
            />
          </router-link>
          <router-link to="/shop/order/history/">
            <btnM class="home-btns__item" btnTxt="発注履歴" color="neutral" />
          </router-link>
          <router-link to="/shop/order/extra/">
            <btnM class="home-btns__item" btnTxt="追加発注" color="neutral" />
          </router-link>
          <router-link to="/shop/address/">
            <btnM class="home-btns__item" btnTxt="納品先登録" color="neutral" />
          </router-link>
          <router-link to="/shop/payments/">
            <btnM class="home-btns__item" btnTxt="請求情報" color="neutral" />
          </router-link>
          <router-link to="/shop/order/">
            <btnL class="home-btns__item-L" btnTxt="発注" color="positive1" />
          </router-link>
          <router-link to="/shop/order/event/">
            <btnL
              class="home-btns__item-L"
              btnTxt="店舗以外の納品先へ発注"
              color="positive2"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.news-blk {
  background: variables.$bg-gray-1;
  box-sizing: border-box;
  padding: 20px 20px 20px;
  height: 265px;
}
.news-blk__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 0 10px;
}
.news-title {
  @extend .f_pageTtl;
  display: block;
  margin-bottom: 20px;
}
.news-list {
  height: 185px;
  overflow: scroll;
}
.news-list__item {
  border-bottom: solid 1px variables.$bg-gray-2;
  padding-bottom: 20px;
  margin-bottom: 15px;
}
.news-date {
  @extend .f_caption;
  color: variables.$txt-pale;
  display: block;
  margin-bottom: 10px;
}
.news-body {
  @extend .f_body;
}
.news-link {
  color: variables.$primary;
  text-decoration: underline;
  @extend .f_body;
  margin-left: 1em;
}
.home-blk {
  box-sizing: border-box;
  padding: 20px 20px 20px;
}
.home-blk__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 0 2px;
}
.home-btns {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include media.min-screen(media.$breakpoint-md) {
    justify-content: center;
  }
}
.home-btns__item {
  margin: 0 7.5px 20px;
}
.home-btns__item-L {
  margin: 0 7.5px 10px;
}
</style>

<script>
import btnM from "@/components/btn/btnM";
import btnL from "@/components/btn/btnL";
import Header from "@/components/shop/header";
import pageTitle from "@/components/shop/pageTitle";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    btnM,
    btnL,
    Header,
    pageTitle
  },
  data: function() {
    return {
      backBtn: false,
      infos: []
    };
  },
  async created() {
    const api = new ApiClient();
    const result = await api.get(`/store/info/list`);
    this.infos = result.list;
  }
};
</script>
