<template>
  <div>
    <Header />
    <pageTitle txt="店舗 一覧" :backBtn="backBtn" />
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <div class="list-head__item col-name">店舗名</div>
          <div class="list-head__item col-shopId">店舗ID</div>
          <div class="list-head__item col-password">パスワード</div>
          <div class="list-head__item col-tel">電話番号</div>
          <div class="list-head__item col-ownerId">オーナーID</div>
          <div class="list-head__item col-code">佐川コードNo.</div>
          <div class="list-head__item col-btn">
            <div>
              <btnS
                class="add"
                btnTxt="新規登録"
                color="primary"
                @click="onNew"
              />
            </div>
          </div>
        </li>
        <li class="list-item" v-for="store in stores" :key="store.id">
          <div class="list-item__data col-name">{{ store.name }}</div>
          <div class="list-item__data col-shopId">{{ store.login_id }}</div>
          <div class="list-item__data col-password">*************</div>
          <div class="list-item__data col-tel">{{ store.tel }}</div>
          <div class="list-item__data col-ownerId">{{ store.owner_name }}</div>
          <div class="list-item__data col-code">{{ store.sagawa_code }}</div>
          <div class="list-item__data col-btn">
            <div>
              <btnS
                class="edit"
                btnTxt="編集"
                color="positive1"
                @click="onEdit(store)"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="pager-wrap">
      <pager :page="page" @movePage="movePage" />
    </div>
    <div id="overlay" :class="{ visible: overlay }">
      <div id="add" v-if="add">
        <div class="dialog">
          <p class="dialog-ttl" v-if="id == ''">新規店舗登録</p>
          <p class="dialog-ttl" v-if="id != ''">店舗情報編集</p>
          <div class="dialog-form">
            <div class="dialog-form__item name">
              <label>店舗名</label>
              <input type="text" v-model="name" />
              <div class="error">{{ errors.name }}</div>
            </div>
            <div class="dialog-form__item id">
              <label>店舗ID</label>
              <input type="text" v-model="login_id" />
              <div class="error">{{ errors.name }}</div>
            </div>
            <div class="dialog-form__item password">
              <label>パスワード</label>
              <input type="password" v-model="password" />
              <div class="error">{{ errors.password }}</div>
            </div>
            <div class="dialog-form__item postal">
              <label>郵便番号</label>
              <input type="text" v-model="zipcode" @change="searchZip" />
              <div class="error">{{ errors.zipcode }}</div>
            </div>
            <div class="dialog-form__item pref">
              <label>住所</label>
              <div class="select">
                <select v-model="pref_id">
                  <option value="">選択してください</option>
                  <option v-for="pref in prefs" :key="pref.id" :value="pref.id">
                    {{ pref.name }}
                  </option>
                </select>
              </div>
              <div class="error">{{ errors.pref_id }}</div>
            </div>
            <div class="dialog-form__item address-1">
              <label></label>
              <input type="text" v-model="shiku" />
              <div class="error">{{ errors.shiku }}</div>
            </div>
            <div class="dialog-form__item address-2">
              <label></label>
              <input type="text" v-model="address" />
              <div class="error">{{ errors.address }}</div>
            </div>
            <div class="dialog-form__item tel">
              <label>電話番号</label>
              <input type="text" v-model="tel" />
              <div class="error">{{ errors.tel }}</div>
            </div>
            <div class="dialog-form__item owner">
              <label>オーナー</label>
              <div class="select">
                <select v-model="owner_id">
                  <option
                    v-for="owner in owners"
                    :key="owner.id"
                    :value="owner.id"
                  >
                    {{ owner.name }}
                  </option>
                </select>
              </div>
              <div class="error">{{ errors.owner_id }}</div>
            </div>
            <div class="dialog-form__item code">
              <label>佐川コードNo.</label>
              <input type="text" v-model="sagawa_code" />
              <div class="error">{{ errors.sagawa_code }}</div>
            </div>
          </div>
        </div>
        <div class="overlay-btn">
          <div @click="(add = !add), (overlay = !overlay)">
            <btnS btnTxt="戻る" color="back" />
          </div>
          <div>
            <btnL btnTxt="この内容で登録する" color="primary" @click="goSave" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#add,
#edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 35px;
  &.name,
  &.id,
  &.password,
  &.address-2,
  &.tel,
  &.owner,
  &.code {
    width: 220px;
  }
  &.postal {
    width: 92px;
  }
  &.pref {
    width: 160px;
  }
  &.address-1 {
    width: 168px;
  }
  &.name,
  &.id,
  &.postal,
  &.pref,
  &.address-1,
  &.tel,
  &.owner {
    margin-right: 20px;
  }
  label {
    margin-bottom: 5px;
    display: block;
    height: 17px;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  width: 1281px;
  margin-left: auto;
  margin-right: auto;
  border-left: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  @extend .f_body;
}

.list-head {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

.list-head__item {
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
}

.list-item {
  display: flex;
  align-items: center;
  height: 60px;
  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
}

.list-item__data {
  display: flex;
  align-items: center;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 15px;
}

.col-name {
  width: 320px;
}

.col-shopId {
  width: 155px;
}

.col-password {
  width: 125px;
}

.col-tel {
  width: 152px;
}

.col-ownerId {
  width: 155px;
}

.col-code {
  width: 153px;
}

.col-btn {
  width: 220px;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 30px;
}

.pager-wrap {
  width: 900px;
  margin: 0 auto;
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import pager from "@/components/common/pager";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL,
    pager
  },
  data: function() {
    return {
      backBtn: true,
      add: false,
      overlay: false,
      page: {},
      pageNumber: 1,
      owners: {},
      errors: {},
      stores: [],
      prefs: [],
      id: "",
      name: "",
      login_id: "",
      password: "",
      zipcode: "",
      pref_id: "",
      shiku: "",
      address: "",
      tel: "",
      owner_id: "",
      sagawa_code: ""
    };
  },
  async created() {
    this._redraw();
    this.prefs = await this.$store.getters.prefectures;
    const api = new ApiClient();
    const result = await api.get("/iseya/owner/list/s");
    this.owners = result.list;
  },
  methods: {
    onNew() {
      this.id = "";
      this.name = "";
      this.login_id = "";
      this.password = "";
      this.zipcode = "";
      this.pref_id = "";
      this.shiku = "";
      this.address = "";
      this.tel = "";
      this.owner_id = "";
      this.sagawa_code = "";
      this.add = true;
      this.overlay = true;
    },
    async onEdit(store) {
      const api = new ApiClient();
      const result = await api.get(`/iseya/store/detail/${store.id}`);

      this.id = result.detail.id;
      this.name = result.detail.name;
      this.login_id = result.detail.login_id;
      this.password = "";
      this.zipcode = result.detail.zipcode;
      this.pref_id = result.detail.pref_id;
      this.shiku = result.detail.shiku;
      this.address = result.detail.address;
      this.tel = result.detail.tel;
      this.owner_id = result.detail.owner_id;
      this.sagawa_code = result.detail.sagawa_code;
      this.add = true;
      this.overlay = true;
    },
    async goSave() {
      this.errors = {};
      const param = {
        name: this.name,
        login_id: this.login_id,
        zipcode: this.zipcode,
        pref_id: this.pref_id,
        shiku: this.shiku,
        address: this.address,
        tel: this.tel,
        owner_id: this.owner_id,
        sagawa_code: this.sagawa_code
      };
      if (this.id) {
        param.id = this.id;
      }
      if (this.password) {
        param.password = this.password;
      }
      const api = new ApiClient();
      const result = await api.post("/iseya/store/save", param);
      const checkResult = api.getErrors(result);
      if (checkResult.isHit) {
        this.errors = checkResult.errors;
        return;
      }
      this.add = false;
      this.overlay = false;
      this._redraw();
    },
    movePage(page) {
      this.pageNumber = page;
      this._redraw();
    },
    async searchZip() {
      if (this.zipcode.length >= 7) {
        const api = new ApiClient();
        const result = await api.searchZip(this.zipcode);
        this.pref_id = result[0].prefcode;
        this.shiku = result[0].address2;
        this.address = result[0].address3;
      }
    },
    async _redraw() {
      const api = new ApiClient();
      const result = await api.get(`/iseya/store/list/${this.pageNumber}`);
      this.stores = result.list;
    }
  }
};
</script>
