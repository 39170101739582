<template>
  <div>
    <Header />
    <pageTitle txt="通常発注 編集" :backBtn="backBtn" />
    <div id="meta">
      <span class="shopName">{{ $route.query.shopName }}</span>
      <span class="shopTel">TEL : {{ $route.query.tel }}</span>
      <btnS class="print" btnTxt="変更を確定" color="primary" @click="save" />
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-head" v-if="orderList.length > 0">
          <ul class="product">
            <li class="date"></li>
            <li class="vacuum"></li>
            <li
              class="product-item"
              v-for="product in orderList[0].products"
              :key="product.item_id"
            >
              {{ product.name }}
            </li>
          </ul>
        </li>
        <orderDetailItem
          v-for="(item, key) in orderList"
          :key="item.id"
          class="weekday"
          :item="item"
          :products="item.products"
          :month="item.month"
          :date="item.day"
          :day="key"
          :orderNum="key"
          :dayName="item.weekName"
          :time="item.thisDayTime"
          edit="edit"
          :class="{
            saturday: item.weekName == '土',
            holiday: item.weekName == '日' || item.is_holiday == '1'
          }"
        />
        <!-- <orderDetailItem class="weekday" :year=2022 :month=2 :date=14 :day=0 :orderNum=0 dayName="月" time="pm" edit="edit" />
        <orderDetailItem class="weekday" :year=2022 :month=2 :date=15 :day=1 :orderNum=1 dayName="火" time="am" edit="edit" />
        <orderDetailItem class="weekday" :year=2022 :month=2 :date=16 :day=2 :orderNum=2 dayName="水" time="pm" edit="edit" />
        <orderDetailItem class="weekday" :year=2022 :month=2 :date=17 :day=3 :orderNum=3 dayName="木" time="pm" edit="edit" />
        <orderDetailItem class="weekday" :year=2022 :month=2 :date=18 :day=4 :orderNum=4 dayName="金" time="pm" edit="edit" />
        <orderDetailItem class="saturday" :year=2022 :month=2 :date=19 :day=5 :orderNum=5 dayName="土" time="pm" edit="edit" />
        <orderDetailItem class="sunday" :year=2022 :month=2 :date=20 :day=6 :orderNum=6 dayName="日" time="am" edit="edit" /> -->
      </ul>
    </div>
    <div id="fixedMenu">
      <router-link
        :to="{
          path: '/iseya/order/detail/',
          query: {
            shopID: $route.query.shopID,
            shopName: $route.query.shopName,
            tel: $route.query.tel,
            date: $route.query.date
          }
        }"
      >
        <btnS btnTxt="戻る" color="back" />
      </router-link>
      <btnS btnTxt="変更を確定" color="primary" @click="save" />
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#meta {
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  background: #fcfcfc;
  box-sizing: border-box;
  padding: 0 30px 0 15px;
}

.meta-btn {
  margin-left: auto;
}

.shopName {
  font-size: 1.5rem;
  font-weight: 500; /* medium */
  letter-spacing: 0.06em;
  margin-right: 30px;
}

.shopTel {
  font-size: 1.5rem;
  font-weight: 500; /* medium */
  letter-spacing: 0.06em;
}

.status {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.status-btn {
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  box-sizing: border-box;
  padding: 0 10px;

  border: solid 1px variables.$txt-default;
  height: 28px;
  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.edit {
  margin-left: 30px;
}

.print {
  margin-left: 30px;
}
#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  border-left: solid 1px variables.$bg-gray-2;
  border-right: solid 1px variables.$bg-gray-2;
  @extend .f_body;
  margin-bottom: 20px;
  display: flex;
}

.list-head {
  width: 160px;
  min-width: 160px;
}

.date {
  height: 100px;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
}

.vacuum {
  height: 30px;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
}

.product-item {
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 15px;
  height: 60px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
  &:after {
    content: "";
    width: 1px;
    height: 40px;
    background: variables.$bg-gray-2;
    position: absolute;
    right: -1px;
    bottom: 0;
    transform: translate(0, 100%);
  }
  &:nth-of-type(odd) {
    background: variables.$bg-gray-1;
  }
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    margin: 0 15px;
  }
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import orderDetailItem from "@/components/iseya/orderDetailItem";
import btnS from "@/components/btn/btnS";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "iseyaDetailEdit",
  components: {
    Header,
    pageTitle,
    orderDetailItem,
    btnS
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      orderList: []
    };
  },
  async created() {
    const param = {
      store_id: this.$route.query.shopID,
      start_date: this.$route.query.date,
      other_id: 0
    };
    const api = new ApiClient();
    const result = await api.post("/store/order/list", param);
    this.orderList = result.list;

    this.today = new Date();
    this.start_date = new Date(this.$route.query.date);
    this.end_date = new Date(this.$route.query.date);
    this.end_date.setDate(this.end_date.getDate() + 6);
  },
  methods: {
    async save() {
      const param = {
        store_id: this.$route.query.shopID,
        list: this.orderList
      };
      const api = new ApiClient();
      await api.post(`/store/order/save`, param);

      const query =
        "?shopID=" +
        this.$route.query.shopID +
        "&shopName=" +
        this.$route.query.shopName +
        "&date=" +
        this.$route.query.date +
        "&tel=" +
        this.$route.query.tel;
      this.$router.push("/iseya/order/detail/" + query);
    }
  }
};
</script>
