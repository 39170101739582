<template>
  <button class="buttonM" :class="color" v-html="btnTxt"></button>
</template>

<script>
export default {
  name: "btnM",
  props: {
    btnTxt: String,
    color: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.buttonM {
  width: 150px;
  height: 60px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  @extend .f_btnTxt;
}
.primary {
  background: variables.$primary;
  color: #fff;
}
.neutral {
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  border: 1px solid #cbcbcb;
  color: variables.$txt-default;
}
.negative {
  background: variables.$negative;
  color:#FFF;
}
.positive1 {
  background: #063D7C;
  color:#FFF;
}
.positive2 {
  background: #5C86B5;
  color:#FFF;
}
</style>