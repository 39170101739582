<template>
  <div class="orderConfirm">
    <Header />
    <pageTitle txt="追加発注内容確認・真空発注" :backBtn="backBtn" />
    <div class="shop-select">
      <span class="shop-select__label">納品先：</span>
      <div class="select-wrap">
        {{ address }}
      </div>
    </div>
    <orderConfirmSingledayAdd
      v-for="(order, key) in orders"
      :key="key"
      :year="order.year"
      :month="order.month"
      :date="order.day"
      :thisWeekDay="order.weekName"
      :day="key + 1"
      class="day2"
      :orderNum="key"
      :extra="true"
    />
    <!-- <orderConfirmSingleday
      :day="0"
      class="holiday day1"
      :orderNum="0"
      :year=2022 :month=2 :date=7 :extra=true
    /> -->
    <div class="order-submit">
      <div class="order-submit__inner">
        <a @click="jump">
          <btnS
            class="order-submit__btn"
            btnTxt="戻って修正する"
            color="back"
          />
        </a>
        <a @click="submit">
          <btnS
            class="order-submit__btn"
            btnTxt="発注を確定する"
            color="primary"
          />
        </a>
      </div>
    </div>
    <transition name="fade">
      <div class="submitMsgDialog" v-show="submitMsg">
        <div class="submitMsgDialog-blk">
          発注が確定しました。<br />5秒後に自動的にトップ画面へ戻ります。
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Header from "@/components/shop/header";
import pageTitle from "@/components/shop/pageTitle";
import btnS from "@/components/btn/btnS";
import orderConfirmSingledayAdd from "@/components/shop/orderConfirmSingledayAdd";
import ApiClient from "@/module/api";

export default {
  name: "shopExtraOrderConfirm",
  components: {
    Header,
    pageTitle,
    btnS,
    orderConfirmSingledayAdd
  },
  computed: {
    orders: function() {
      return this.$store.state.shopOrder.data.order;
    }
  },
  methods: {
    async submit() {
      const state = await this.$store.getters["getStoreLoginData"];
      const param = {
        store_id: state.shop_id,
        extra: 1,
        list: this.orders
      };
      const api = new ApiClient();
      await api.post(`/store/order/save`, param);
      this.submitMsg = true;
      const obj = this;
      setTimeout(function() {
        obj.$router.push("/shop/home/");
      }, 5000);
    },
    jump: function() {
      this.$router
        .push({
          name: "shopExtraOrder",
          params: {
            no_init: true
          }
        })
        .catch(() => {});
    }
  },
  props: {
    address: String,
    day1: {
      type: Array,
      require: false
    },
    day2: {
      type: Array,
      require: false
    },
    day3: {
      type: Array,
      require: false
    },
    day4: {
      type: Array,
      require: false
    },
    day5: {
      type: Array,
      require: false
    },
    day6: {
      type: Array,
      require: false
    },
    day7: {
      type: Array,
      require: false
    },
    day1Sum: Number,
    day2Sum: Number,
    day3Sum: Number,
    day4Sum: Number,
    day5Sum: Number,
    day6Sum: Number,
    day7Sum: Number,
    day1boxNum: Number,
    day2boxNum: Number,
    day3boxNum: Number,
    day4boxNum: Number,
    day5boxNum: Number,
    day6boxNum: Number,
    day7boxNum: Number
  },
  data: function() {
    return {
      backBtn: true,
      submitMsg: false
    };
  }
};
</script>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.orderConfirm {
  padding-bottom: 90px;
}
.orderConfirm-list {
  display: flex;
  flex-wrap: wrap;
}
.orderConfirm-list__item {
  width: 50%;
  height: 40px;
  border-bottom: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  align-items: center;
}
.orderConfirm-list__item-name {
  @extend .f_body;
}
.orderConfirm-list__item-amount {
  @extend .f_body;
  margin-left: auto;
}
.orderConfirm-date {
  width: 100%;
  height: 60px;
  display: block;
  @extend .f_pageTtl;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  align-items: center;
  &.holiday {
    color: variables.$sunday;
  }
  &.saturday {
    color: variables.$saturday;
  }
}
.orderConfirm-singleDay__total {
  background: variables.$bg-gray-2;
  height: 60px;
  display: flex;
  align-items: center;
}
.orderConfirm-singleDay__total-price,
.orderConfirm-singleDay__total-box {
  width: 50%;
  box-sizing: border-box;
  padding: 0 15px;
  width: 50%;
  @extend .f_body;
}
.orderConfirm-singleDay__total-num {
  @extend .f_number;
  margin-left: 13px;
  margin-right: 1px;
}
.order-submit {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 90px;
  //background: variables.$bg-gray-2;
  background: transparent;
  backdrop-filter: blur(5px);
  --webkit-backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.01);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 15px 0 25px;
  transition: all 0.5s ease;
  z-index: 10;
}
.order-submit__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  position: relative;
}
.order-submit__btn {
  margin: 0 15px;
}
.submitMsgDialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}
.submitMsgDialog-blk {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 400px;
  background: #fff;
  border: solid 1px variables.$border-1;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 30px;
  @extend .f_body;
}
.shop-select {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 0 30px;
  @extend .f_body;
}
.shop-select__label {
  @extend .f_body;
  margin-right: 10px;
  white-space: nowrap;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
