<template>
  <div>
    <Header />
    <pageTitle txt="オーナー 一覧" :backBtn="backBtn" />
    <div id="wrapper">
      <div>
        <btnS class="add" btnTxt="新規登録" color="primary" @click="onNew" />
      </div>
      <ul class="list">
        <li class="list-head">
          <div class="list-head__item col-name">オーナー名</div>
          <div class="list-head__item col-id">オーナーID</div>
          <div class="list-head__item col-password">パスワード</div>
          <div class="list-head__item col-password">割引適用店舗</div>
          <div class="list-head__item col-btn"></div>
        </li>
        <li class="list-item" v-for="owner in owners" :key="owner.id">
          <div class="list-item__data col-name">
            <span class="label" v-if="owner.discount_store_name === ''">
              割引適用店舗が未設定です
            </span>
            {{ owner.name }}
          </div>
          <div class="list-item__data col-id">{{ owner.login_id }}</div>
          <input
            class="list-item__data col-password"
            value="xxxxxxx"
            type="password"
            readonly
          />
          <div class="list-item__data col-password">
            <span v-if="owner.discount_store_name">
              {{ owner.discount_store_name }}
            </span>
          </div>
          <div class="list-item__data col-btn">
            <div>
              <btnS
                class="edit"
                btnTxt="編集"
                color="positive1"
                @click="onEdit(owner)"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="pager-wrap">
      <pager :page="page" @movePage="movePage" />
    </div>
    <div id="overlay" :class="{ visible: overlay }">
      <div id="add" v-if="add">
        <div class="dialog">
          <p class="dialog-ttl" v-if="id == ''">オーナー情報登録</p>
          <p class="dialog-ttl" v-if="id != ''">オーナー情報編集</p>
          <div class="dialog-form">
            <div class="dialog-form__item name">
              <label>オーナー名</label>
              <input type="text" v-model="name" />
              <div class="error">{{ errors.name }}</div>
            </div>
            <div class="dialog-form__item id">
              <label>オーナーID</label>
              <input type="text" v-model="login_id" />
              <div class="error">{{ errors.login_id }}</div>
            </div>
            <div class="dialog-form__item password">
              <label>パスワード</label>
              <input type="password" v-model="password" />
              <div class="error">{{ errors.password }}</div>
            </div>
            <div class="dialog-form__item shop">
              <label>割引適用店舗</label>
              <div class="select">
                <select v-model="discount_store">
                  <option value="0">選択してください</option>
                  <option
                    v-for="store in stores"
                    :key="store.id"
                    :value="store.id"
                  >
                    {{ store.name }}
                  </option>
                </select>
              </div>
              <div class="error">{{ errors.discount_store }}</div>
            </div>
          </div>
        </div>
        <div class="overlay-btn">
          <div v-on:click="(add = !add), (overlay = !overlay)">
            <btnS btnTxt="戻る" color="back" />
          </div>
          <div>
            <btnL btnTxt="この内容で登録する" color="primary" @click="goSave" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#add,
#edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 35px;
  &.name,
  &.id,
  &.password,
  &.shop {
    width: 220px;
  }
  &.name,
  &.id {
    margin-right: 20px;
  }
  label {
    margin-bottom: 5px;
    display: block;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.add {
  position: absolute;
  left: calc(50% + 510px);
  top: 100px;
}

.list {
  box-sizing: border-box;
  width: 901px;
  margin-left: auto;
  margin-right: auto;
  border-left: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  @extend .f_body;
}

.list-head {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

.list-head__item {
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.list-item {
  display: flex;
  align-items: stretch;
  min-height: 65px;
  box-sizing: border-box;
  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
}

.list-item__data {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-right: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 10px 15px;
  position: relative;
  .label {
    //position: absolute;
    color: variables.$negative;
    @extend .f_caption;
    border: solid 1px variables.$negative;
    padding: 0 5px;
    margin-bottom: 5px;
  }
}

.col-name {
  width: 300px;
}

.col-id {
  width: 155px;
}

.col-password {
  width: 125px;
}

.col-btn {
  width: 195px;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 30px;
}

.pager-wrap {
  width: 900px;
  margin: 0 auto;
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import pager from "@/components/common/pager";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL,
    pager
  },
  data: function() {
    return {
      backBtn: true,
      edit: false,
      add: false,
      overlay: false,
      page: {},
      pageNumber: 1,
      owners: {},
      errors: {},
      stores: [],
      id: "",
      name: "",
      login_id: "",
      password: "",
      discount_store: ""
    };
  },
  async created() {
    this._redraw();
  },
  methods: {
    onNew() {
      this.id = "";
      this.name = "";
      this.login_id = "";
      this.password = "";
      this.discount_store = "";
      this.add = true;
      this.overlay = true;
    },
    async onEdit(owner) {
      const api = new ApiClient();
      const result = await api.get(`/iseya/owner/store/${owner.id}`);
      this.stores = result.list;
      this.id = owner.id;
      this.name = owner.name;
      this.login_id = owner.login_id;
      this.password = "";
      this.discount_store = owner.discount_store;
      this.add = true;
      this.overlay = true;
    },
    async goSave() {
      this.errors = {};
      const param = {
        name: this.name,
        login_id: this.login_id,
        discount_store: this.discount_store
      };
      if (this.id) {
        param.id = this.id;
      }
      if (this.password) {
        param.password = this.password;
      }
      const api = new ApiClient();
      const result = await api.post("/iseya/owner/save", param);
      const checkResult = api.getErrors(result);
      if (checkResult.isHit) {
        this.errors = checkResult.errors;
        return;
      }
      this.add = false;
      this.overlay = false;
      this._redraw();
    },
    movePage(page) {
      this.pageNumber = page;
      this._redraw();
    },
    async _redraw() {
      const api = new ApiClient();
      const result = await api.get(`/iseya/owner/list/${this.pageNumber}`);
      this.owners = result.list;
    }
  }
};
</script>
