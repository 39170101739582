<template>
  <li class="list-item">
    <div class="period">
      {{ stMonth }}/{{ stDay }} 〜 {{ endMonth }}/{{ endDay }}
    </div>
    <div class="noorder" :class="{ positive: noorder > 0 }" v-if="order">
      未発注店舗 {{ noorder }}件
    </div>

    <router-link
      :to="{
        path: url,
        query: {
          stYear: stYear,
          stMonth: stMonth,
          stDay: stDay,
          endYear: endYear,
          endMonth: endMonth,
          endDay: endDay,
          noorder: noorder
        }
      }"
      class="detail"
    >
      <btnS btnTxt="詳細を見る" color="positive1" />
    </router-link>
  </li>
</template>

<script>
import btnS from "@/components/btn/btnS";
export default {
  name: "orderWeeklyItem",
  components: {
    btnS
  },
  data: function() {
    return {
      backBtn: true
    };
  },

  props: {
    period: String,
    noorder: Number,
    order: Boolean,
    extra: Boolean,
    url: String,
    stYear: Number,
    stMonth: Number,
    stDay: Number,
    endYear: Number,
    endMonth: Number,
    endDay: Number
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.list-item {
  width: 50%;
  min-width: 160px;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding-right: 30px;
  &:nth-of-type(odd) {
    border-right: solid 1px variables.$bg-gray-2;
  }
  &:nth-of-type(4n),
  &:nth-of-type(4n - 1) {
    background: variables.$bg-gray-1;
  }
}

.period {
  width: 130px;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  @extend .f_body;
  padding: 0 15px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.noorder {
  display: flex;
  align-items: center;
  margin-right: 20px;
  position: relative;
  width: 145px;
  height: 30px;

  box-sizing: border-box;
  padding-left: 12px;

  color: variables.$scrollbar-back;
  border: solid 1px variables.$scrollbar-back;
  opacity: 0.2;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: variables.$scrollbar-back transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
  }

  &.positive {
    color: variables.$negative;
    border: solid 1px variables.$negative;
    opacity: 1;
    &:after {
      border-color: variables.$negative transparent transparent transparent;
    }
  }
}

.status {
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.status-btn {
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  box-sizing: border-box;
  padding: 0 10px;

  border: solid 1px variables.$txt-default;
  height: 28px;
  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.detail {
  margin-left: auto;
}
</style>
