<template>
  <div class="shipment">
    <Header />
    <pageTitle txt="荷物番号確認" :backBtn="backBtn" />
    <ul class="shipment-list">
      <li class="shipment-list__item" v-for="(row, key) in list" :key="key">
        <span class="shipment-list__date">
          {{ new Date(row.date) | dateFormat }} {{ row.time }}
        </span>
        <span class="shipment-list__num">荷物番号 : {{ row.code }}</span>
        <a href="">
          <btnL
            class="home-btns__item-L"
            btnTxt="現在の状況を表示する"
            color="positive1"
            icon="external"
            @click="show_sagawa"
          />
        </a>
        <span class="shipment-list__cap"
          >佐川急便のサイトにジャンプします。</span
        >
      </li>
    </ul>
  </div>
</template>
<script>
import Header from "@/components/shop/header";
import pageTitle from "@/components/shop/pageTitle";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "orderHistory",
  components: {
    Header,
    pageTitle,
    btnL
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      list: []
    };
  },
  async created() {
    const api = new ApiClient();
    const result = await api.get("/store/shipment/list");
    this.list = result.list;
  },
  methods: {
    show_sagawa() {
      window.open("https://k2k.sagawa-exp.co.jp/p/sagawa/web/okurijoinput.jsp");
    }
  }
};
</script>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.shipment-list {
  max-width: 375px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 30px;
}
.shipment-list__item {
  box-sizing: border-box;
  padding: 20px 0 30px;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px variables.$bg-gray-2;
}
.shipment-list__date {
  display: block;
  @extend .f_body;
  margin-bottom: 10px;
}
.shipment-list__num {
  display: block;
  @extend .f_body;
  margin-bottom: 20px;
}
.shipment-list__cap {
  display: block;
  @extend .f_body;
  text-align: center;
  margin-top: 5px;
}
</style>
