<template>
  <div class="home">
    <Header />
    <pageTitle txt="ホーム" :backBtn="backBtn" />
    <div class="news-blk" v-if="readNormal || readExtra">
      <div class="news-blk__inner">
        <span class="news-title">最新情報</span>
        <ul class="news-list">
          <li class="news-list__item" v-if="readNormal">
            <span class="news-date">{{ today | dateFormat }}</span>
            <a href="/iseya/order/" class="news-link">
              未確認の通常発注があります。
            </a>
          </li>
          <li class="news-list__item" v-if="readExtra">
            <span class="news-date">{{ today | dateFormat }}</span>
            <a href="/iseya/order/extraOrder/" class="news-link">
              未確認の追加発注があります。
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="home-blk">
      <div class="home-blk__inner">
        <div class="home-blk__row">
          <p class="home-btns__ttl">通常発注</p>
          <div class="home-btns">
            <router-link to="/iseya/order/weekly/">
              <btnM
                class="home-btns__item"
                btnTxt="週間種類別集計"
                color="neutral"
              />
            </router-link>
            <router-link to="/iseya/order/">
              <btnM
                class="home-btns__item"
                btnTxt="通常発注一覧"
                color="neutral"
              />
            </router-link>
          </div>
        </div>
        <div class="home-blk__row">
          <p class="home-btns__ttl">追加発注</p>
          <div class="home-btns">
            <router-link to="/iseya/order/extraWeekly/">
              <btnM
                class="home-btns__item"
                btnTxt="追加発注<br />週間種類別集計"
                color="neutral"
              />
            </router-link>
            <router-link to="/iseya/order/extraOrder/">
              <btnM
                class="home-btns__item"
                btnTxt="追加発注一覧"
                color="neutral"
              />
            </router-link>
          </div>
        </div>
        <div class="home-blk__row">
          <p class="home-btns__ttl">発送処理</p>
          <div class="home-btns">
            <router-link to="/iseya/shipping/instructions/">
              <btnM
                class="home-btns__item"
                btnTxt="発送指示書"
                color="neutral"
              />
            </router-link>
            <router-link to="/iseya/shipping/deliver/">
              <btnM class="home-btns__item" btnTxt="納品書" color="neutral" />
            </router-link>
            <router-link to="/iseya/shipping/csv/">
              <btnM
                class="home-btns__item"
                btnTxt="荷物番号CSV登録"
                color="neutral"
              />
            </router-link>
          </div>
        </div>
        <div class="home-blk__row">
          <p class="home-btns__ttl">売上管理</p>
          <div class="home-btns">
            <router-link to="/iseya/sales/daily/">
              <btnM
                class="home-btns__item"
                btnTxt="商品別売上日報"
                color="neutral"
              />
            </router-link>
            <router-link to="/iseya/sales/shop/daily/">
              <btnM
                class="home-btns__item"
                btnTxt="店舗別売上日報"
                color="neutral"
              />
            </router-link>
            <router-link to="/iseya/sales/monthly/">
              <btnM
                class="home-btns__item"
                btnTxt="商品別売上月報"
                color="neutral"
              />
            </router-link>
            <router-link to="/iseya/sales/shop/monthly/">
              <btnM
                class="home-btns__item"
                btnTxt="店舗別売上月報"
                color="neutral"
              />
            </router-link>
          </div>
        </div>
        <div class="home-blk__row">
          <p class="home-btns__ttl">マスタ管理</p>
          <div class="home-btns">
            <router-link to="/iseya/master/owner/">
              <btnM
                class="home-btns__item"
                btnTxt="オーナー情報"
                color="neutral"
              />
            </router-link>
            <router-link to="/iseya/master/shop/">
              <btnM class="home-btns__item" btnTxt="店舗情報" color="neutral" />
            </router-link>
            <router-link to="/iseya/master/transfer/">
              <btnM class="home-btns__item" btnTxt="発送登録" color="neutral" />
            </router-link>
            <router-link to="/iseya/master/product/">
              <btnM class="home-btns__item" btnTxt="商品情報" color="neutral" />
            </router-link>
          </div>
        </div>
        <div class="home-blk__row">
          <p class="home-btns__ttl">お知らせ管理</p>
          <div class="home-btns">
            <router-link to="/iseya/news/">
              <btnM
                class="home-btns__item"
                btnTxt="お知らせ一覧"
                color="neutral"
              />
            </router-link>
          </div>
        </div>
        <div class="home-blk__row">
          <p class="home-btns__ttl">請求管理</p>
          <div class="home-btns">
            <router-link to="/iseya/invoice/archive/">
              <btnM
                class="home-btns__item"
                btnTxt="過去の請求"
                color="neutral"
              />
            </router-link>
            <router-link to="/iseya/invoice/recent/">
              <btnM
                class="home-btns__item"
                btnTxt="当月の請求予定"
                color="neutral"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.news-blk {
  background: variables.$bg-gray-1;
  box-sizing: border-box;
  padding: 20px 20px 20px;
  height: 145px;
}
.news-blk__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 0 10px;
}

.news-title {
  @extend .f_pageTtl;
  display: block;
  margin-bottom: 20px;
}
.news-list {
  height: 185px;
  overflow: scroll;
}
.news-list__item {
  margin-bottom: 15px;
  padding-bottom: 10px;
  &:not(:last-of-type) {
    border-bottom: solid 1px variables.$bg-gray-2;
  }
}
.news-date {
  @extend .f_caption;
  color: variables.$txt-pale;
  margin-right: 25px;
}
.news-link {
  color: variables.$primary;
  text-decoration: underline;
  @extend .f_body;
}
.home-blk {
  box-sizing: border-box;
  padding: 20px 20px 20px;
}
.home-blk__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  box-sizing: border-box;
  padding: 0 2px;
}
.home-blk__row {
  display: flex;
  align-items: center;
  padding: 25px 0 0;

  &:not(:last-of-type) {
    border-bottom: solid 1px variables.$bg-gray-2;
  }
}
.home-btns {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.home-btns__ttl {
  font-size: 1.5rem;
  font-weight: 700; /* bold */
  letter-spacing: 0.06em;
  width: 160px;
  padding-bottom: 25px;
}
.home-btns__item {
  margin: 0 5px 25px;
}
</style>

<script>
import btnM from "@/components/btn/btnM";
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import OrderReaded from "@/components/mixins/OrderReaded.vue";
import Global from "@/components/mixins/Global.vue";

export default {
  name: "ShopHome",
  components: {
    btnM,
    Header,
    pageTitle
  },
  mixins: [Global, OrderReaded],
  data: function() {
    return {
      backBtn: false,
      today: "",
      readNormal: false,
      readExtra: false
    };
  },
  async created() {
    this.today = new Date();
    const normal = await this.normalReaded();
    const extra = await this.extraReaded();
    const obj = this;
    normal.forEach(function(row) {
      if (row.is_read) {
        obj.readNormal = true;
        return false;
      }
    });
    extra.forEach(function(row) {
      if (row.is_read) {
        obj.readExtra = true;
        return false;
      }
    });
  }
};
</script>
