<template>
  <div>
    <Header />
    <pageTitle txt="通常発注 詳細" :backBtn="backBtn" backURL="/iseya/order/" />
    <div id="meta">
      <span class="shopName">{{ $route.query.shopName }}</span>
      <span class="shopTel">TEL : {{ $route.query.tel }}</span>
      <div class="meta-btn">
        <router-link
          :to="{
            path: '/iseya/order/detail/edit/',
            query: {
              shopID: $route.query.shopID,
              shopName: $route.query.shopName,
              tel: $route.query.tel,
              date: $route.query.date
            }
          }"
        >
          <btnS class="edit" btnTxt="編集" color="positive1" />
        </router-link>
        <div v-on:click="print = !print">
          <btnS class="print" btnTxt="印刷" color="primary" />
        </div>
      </div>
    </div>
    <div id="wrapper">
      <ul class="list">
        <li class="list-head" v-if="orderList.length > 0">
          <ul class="product">
            <li class="date"></li>
            <li class="vacuum"></li>
            <li
              class="product-item"
              v-for="product in orderList[0].products"
              :key="product.item_id"
            >
              {{ product.name }}
            </li>
          </ul>
        </li>
        <orderDetailItem
          v-for="(item, key) in orderList"
          :key="item.id"
          class="weekday"
          :item="item"
          :products="item.products"
          :month="item.month"
          :date="item.day"
          :day="key"
          :orderNum="key"
          :dayName="item.weekName"
          :time="item.thisDayTime"
          :class="{
            saturday: item.weekName == '土',
            holiday: item.weekName == '日' || item.is_holiday == '1'
          }"
        />
      </ul>
    </div>
    <div id="overlay" :class="{ visible: print }">
      <div id="print">
        <div id="print-meta">
          <p class="print-ttl">発注書（{{ $route.query.shopName }}）</p>
          <p class="print-period">
            {{ start_date | dateFormatShort }} ～
            {{ end_date | dateFormatShort }}
          </p>
          <span class="print-shopName">{{ $route.query.shopName }}</span>
          <span class="print-shopTel">TEL : {{ $route.query.tel }}</span>
          <div class="print-time">
            {{ today | dateFormatShort }} 現在
            <br />
            1/1頁
          </div>
        </div>
        <ul class="list" v-if="orderList.length > 0">
          <li class="list-head">
            <ul class="product">
              <li class="date"></li>
              <li class="vacuum"></li>
              <li
                class="product-item"
                v-for="product in orderList[0].products"
                :key="product.id"
              >
                {{ product.name }}
              </li>
            </ul>
          </li>
          <orderDetailItem
            v-for="(item, key) in orderList"
            :key="item.id"
            class="weekday"
            :products="item.products"
            :month="item.month"
            :date="item.day"
            :day="key"
            :orderNum="key"
            :dayName="item.weekName"
            :time="item.thisDayTime"
            :class="{
              saturday: item.weekName == '土',
              holiday: item.weekName == '日' || item.is_holiday == '1'
            }"
          />
        </ul>
      </div>
      <div id="fixedMenu">
        <div v-on:click="print = !print">
          <btnS btnTxt="戻る" color="back" />
        </div>
        <router-link to="/iseya/order/detail/edit/">
          <btnL btnTxt="この内容で印刷する" color="primary" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}
#print {
  width: 945px;
  height: 668px;
  background: #fff;
  box-sizing: border-box;
  padding: 30px;

  position: absolute;
  left: 50%;
  top: 20px;
  transform: translate(-50%, 0);
  .list {
    transform: scale(0.69);
    transform-origin: top left;
  }
}

#print-meta {
  margin-bottom: 20px;
}

.print-time {
  position: absolute;
  right: 30px;
  top: 50px;
  font-size: 1rem;
  letter-spacing: 0.06em;
  text-align: right;
}

.print-ttl {
  text-align: center;
  @extend .f_pageTtl;
  margin-bottom: 10px;
}

.print-period {
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.06em;
  margin-bottom: 30px;
}

.print-shopName,
.print-shopTel {
  font-size: (1.5 * 0.69rem);
  letter-spacing: 0.06em;
  margin-right: (30 * 0.69px);
}

#fixedMenu {
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 89px;
  background: transparent;
  backdrop-filter: blur(5.3040900230407715px);
  --webkit-backdrop-filter: blur(5.3040900230407715px);
  background-color: rgba(255, 255, 255, 0.33);

  display: flex;
  justify-content: center;
  align-items: center;
  .buttonS {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(0, -50%);
  }
}
#meta {
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  background: #fcfcfc;
  box-sizing: border-box;
  padding: 0 30px 0 15px;
}

.meta-btn {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.shopName {
  font-size: 1.5rem;
  font-weight: 500; /* medium */
  letter-spacing: 0.06em;
  margin-right: 30px;
}

.shopTel {
  font-size: 1.5rem;
  font-weight: 500; /* medium */
  letter-spacing: 0.06em;
}

.status {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.status-btn {
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  box-sizing: border-box;
  padding: 0 10px;

  border: solid 1px variables.$txt-default;
  height: 28px;
  margin-left: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.edit {
  margin-left: 30px;
}

.print {
  margin-left: 30px;
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  border-left: solid 1px variables.$bg-gray-2;
  //border-right: solid 1px variables.$bg-gray-2;
  @extend .f_body;
  margin-bottom: 20px;
  display: flex;
}

.list-head {
  width: 160px;
  min-width: 160px;
}

.date {
  height: 100px;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
}

.vacuum {
  height: 30px;
  box-sizing: border-box;
  border-right: solid 1px variables.$bg-gray-2;
}

.product-item {
  border-right: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 15px;
  height: 60px;
  display: flex;
  align-items: center;
  &:nth-of-type(odd) {
    background: variables.$bg-gray-1;
  }
}
</style>

<style lang="scss">
@media print {
  @page {
    size: landscape;
    margin: 0;
  }
  #wrapper,
  #fixedMenu {
    display: none !important;
  }
  #print {
    width: 251mm;
    height: 172mm;
    page-break-after: always;

    .list {
      transform: scale(1);
    }
  }
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import orderDetailItem from "@/components/iseya/orderDetailItem";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import Global from "@/components/mixins/Global.vue";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    orderDetailItem,
    btnS,
    btnL
  },
  mixins: [Global],
  data: function() {
    return {
      backBtn: true,
      today: "",
      start_date: "",
      end_date: "",
      print: false,
      shopName: "",
      orderList: []
    };
  },
  async created() {
    const param = {
      store_id: this.$route.query.shopID,
      start_date: this.$route.query.date,
      other_id: 0
    };
    const api = new ApiClient();
    const result = await api.post("/store/order/list", param);
    this.orderList = result.list;

    this.today = new Date();
    this.start_date = new Date(this.$route.query.date);
    this.end_date = new Date(this.$route.query.date);
    this.end_date.setDate(this.end_date.getDate() + 6);
  }
};
</script>
