<template>
  <div class="order">
    <Header @change_shop="changeShop" />
    <pageTitle txt="追加発注商品選択" :backBtn="backBtn" backURL="/shop/home" />
    <div id="date-list">
      <div
        v-for="(order, key) in orders"
        :key="key"
        v-on:click="dayActivate(key)"
        :class="{
          seeing: activeDay == key,
          hasOrder: order.thisDayQt > 0,
          saturday: order.weekName == '土',
          holiday: order.weekName == '日' || order.is_holiday == '1'
        }"
        class="date-list__item"
      >
        <!-- 祝日の場合は .holiday 付与 -->
        <div class="date-list__item-date">
          <span
            class="date-month"
            :class="{
              holiday: order.is_holiday == '1'
            }"
          >
            {{ order.month }} /
          </span>
          <!-- 祝日の場合は .holiday 付与 -->
          <span class="date-day" :class="{ holiday: order.is_holiday == '1' }">
            {{ order.day }}
          </span>
          <!-- 祝日の場合は .holiday 付与 -->
        </div>
        <span
          class="date-daytype"
          :class="{ holiday: order.is_holiday == '1' }"
        >
          {{ order.weekName }}
        </span>
        <!-- 祝日の場合は .holiday 付与 -->
      </div>
    </div>
    <div id="orderMenuWrap">
      <div class="orderMenuWrap__inner">
        <orderMenuAdd
          class=""
          v-for="(order, key) in orders"
          :key="key"
          :year="order.year"
          :month="order.month"
          :date="order.day"
          :thisWeekDay="order.weekName"
          :orderNum="key"
          :products="order.products"
          :extra="true"
          :class="{
            saturday: order.weekName == '土',
            holiday: order.weekName == '日' || order.is_holiday == '1'
          }"
        />
      </div>
      <orderState
        :activeDay="activeDay"
        :jumpTo="this.jumpTo"
        :address="this.selectedAddress"
      />
    </div>
  </div>
</template>
<script>
import Header from "@/components/shop/header";
import pageTitle from "@/components/shop/pageTitle";
import orderMenuAdd from "@/components/shop/orderMenuAdd";
import orderState from "@/components/shop/orderState";
export default {
  name: "shopExtraOrder",
  components: {
    Header,
    pageTitle,
    orderMenuAdd,
    orderState
  },
  computed: {
    orders: function() {
      return this.$store.state.shopOrder.data.order;
    },
    products: function() {
      return this.$store.state.products;
    }
  },
  async created() {
    this.$store.dispatch("getProducts");
    const state = await this.$store.getters["getStoreLoginData"];
    this.selectedAddress = state.shop_name;
    if (!this.$route.params.no_init) {
      await this.$store.commit("initOrderData", {
        store_id: state.shop_id,
        extra: true
      });
    }
  },
  data: function() {
    return {
      jumpTo: "shopExtraOrderConfirm",
      activeDay: 0,
      selectedAddress: "",
      backBtn: true
    };
  },
  methods: {
    changeShop(shop) {
      this.selectedAddress = shop.name;
    },
    dayActivate(index) {
      this.activeDay = index;
      this.sum = this.orders[this.activeDay].thisDayWeight / 100;
      this.boxNum = this.orders[this.activeDay].thisDayBoxQt;
      let wW = window.innerWidth;
      let distance = wW * index;
      let val = "translateX(-" + distance + "px)";
      let target = document.getElementsByClassName("orderMenuWrap__inner");
      target[0].style.transform = val;
    }
  }
};
</script>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#date-list {
  display: flex;
  margin-bottom: 18px;
}
.date-list__item {
  width: calc(100% / 7);
  height: 60px;
  background: variables.$bg-gray-2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  position: relative;
  cursor: pointer;
  &:after {
    transition: all 0.5s ease-in-out;
    content: "";
    position: absolute;
    left: 50%;
    bottom: -6px;
    width: 6px;
    height: 6px;
    background: #86d901;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, 100%) scale(1);
  }
  &.seeing {
    background: #f7f7f6;
  }
  &.hasOrder {
    &:after {
      opacity: 1;
      transform: translate(-50%, 100%) scale(1);
      animation: hasOrder 0.5s forwards;
    }
  }
}
@keyframes hasOrder {
  0% {
    transform: translate(-50%, 100%) scale(10);
  }
  100% {
    transform: translate(-50%, 100%) scale(1);
  }
}
.date-list__item-date {
  position: relative;
}
.holiday {
  .date-month,
  .date-day,
  .date-daytype {
    color: variables.$sunday;
  }
}
.saturday {
  .date-month,
  .date-day,
  .date-daytype {
    color: variables.$saturday;
  }
}
.date-month {
  font-size: 1rem;
  font-weight: 700; /* bold */
  margin-right: 0.25em;
  position: relative;
  top: -0.1em;
}
.date-day {
  font-size: 1.4rem;
  font-weight: 700; /* bold */
}
.date-daytype {
  font-size: 1.3rem;
  font-weight: 700; /* bold */
  letter-spacing: 0.1em;
}
#orderMenuWrap {
  position: relative;
  width: 100vw;
  overflow: hidden;
}
.orderMenuWrap__inner {
  width: 700vw;
  display: flex;
  transition: all 0.3s ease;
}
.orderMenu {
  padding-bottom: 90px;
  width: 100vw;
}
</style>
