<template>
  <div class="order">
    <Header @change_shop="changeShop" />
    <pageTitle txt="店舗以外の発注" :backBtn="backBtn" backURL="/shop/home" />
    <div class="shop-select">
      <span class="shop-select__label">納品先：</span>
      <div class="select-wrap">
        <select v-model="selectedDeliveryID" @change="changeDelivery">
          <option
            v-for="(delivery, key) in deliveries"
            :key="key"
            :value="delivery.id"
          >
            {{ delivery.name }}
          </option>
        </select>
      </div>
    </div>
    <div id="date-list">
      <div
        v-for="(order, key) in orders"
        :key="key"
        @click="dayActivate(key)"
        :class="{
          seeing: activeDay == key,
          hasOrder: order.thisDayQt > 0,
          saturday: order.weekName == '土',
          holiday: order.weekName == '日' || order.is_holiday == '1'
        }"
        class="date-list__item"
      >
        <div class="date-list__item-date">
          <span class="date-month">{{ order.month }} /</span>
          <span class="date-day">{{ order.day }}</span>
        </div>
        <span class="date-daytype">{{ order.weekName }}</span>
      </div>
    </div>
    <div id="orderMenuWrap">
      <div class="orderMenuWrap__inner">
        <orderMenu
          class=""
          v-for="(order, key) in orders"
          :key="key"
          :year="order.year"
          :month="order.month"
          :date="order.day"
          :thisWeekDay="order.weekName"
          :orderNum="key"
          :products="order.products"
          :class="{
            saturday: order.weekName == '土',
            holiday: order.weekName == '日' || order.is_holiday == '1'
          }"
        />
      </div>
      <orderState
        :activeDay="activeDay"
        :jumpTo="this.jumpTo"
        :address="this.selectedDeliveryID"
      />
    </div>
  </div>
</template>
<script>
import Header from "@/components/shop/header";
import pageTitle from "@/components/shop/pageTitle";
import orderMenu from "@/components/shop/orderMenu";
import orderState from "@/components/shop/orderState";
import ApiClient from "@/module/api";

export default {
  name: "shopExtraOrder",
  components: {
    Header,
    pageTitle,
    orderMenu,
    orderState
  },
  data: function() {
    return {
      jumpTo: "shopEventOrderConfirm",
      activeDay: 0,
      selectedDeliveryID: "0",
      selectedDeliveryName: "",
      backBtn: true,
      deliveries: []
    };
  },
  computed: {
    orders: function() {
      return this.$store.state.shopOrder.data.order;
    },
    products: function() {
      return this.$store.state.products;
    }
  },
  async created() {
    this._redraw();
  },
  methods: {
    changeShop() {
      this._redraw();
    },
    dayActivate(index) {
      this.activeDay = index;
      this.sum = this.orders[this.activeDay].thisDayWeight / 100;
      this.boxNum = this.orders[this.activeDay].thisDayBoxQt;
      let wW = window.innerWidth;
      let distance = wW * index;
      let val = "translateX(-" + distance + "px)";
      let target = document.getElementsByClassName("orderMenuWrap__inner");
      target[0].style.transform = val;
    },
    changeDelivery() {
      this.getDeliveryName();
      this._initOrderData();
    },
    getDeliveryName() {
      const obj = this;
      this.deliveries.forEach(function(row) {
        if (row.id == obj.selectedDeliveryID) {
          obj.selectedDeliveryName = row.name;
          return;
        }
      });
    },
    async _redraw() {
      const loginData = await this.$store.getters[`getStoreLoginData`];
      const param = {
        store_id: loginData.shop_id
      };
      const api = new ApiClient();
      const result = await api.post(`/store/delivery/list`, param);
      this.deliveries = result.list;
      if (this.deliveries.length == 0) {
        alert("まだ納品先が登録されていません。");
        this.$router.push("/shop/home/");
        return;
      }
      this.$store.dispatch("getProducts");
      if (!this.$route.params.no_init) {
        this.selectedDeliveryID = this.deliveries[0].id;
        this.selectedDeliveryName = this.deliveries[0].name;
        this._initOrderData();
      } else {
        this.selectedDeliveryID = this.$route.params.delivery_id;
      }
    },
    async _initOrderData() {
      const loginData = await this.$store.getters[`getStoreLoginData`];
      await this.$store.commit("initOrderData", {
        store_id: loginData.shop_id,
        other_id: this.selectedDeliveryID
      });
    }
  }
};
</script>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#date-list {
  display: flex;
  margin-bottom: 18px;
}
.date-list__item {
  width: calc(100% / 7);
  height: 60px;
  background: variables.$bg-gray-2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
  position: relative;
  cursor: pointer;
  &:after {
    transition: all 0.5s ease-in-out;
    content: "";
    position: absolute;
    left: 50%;
    bottom: -6px;
    width: 6px;
    height: 6px;
    background: #86d901;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, 100%) scale(1);
  }
  &.seeing {
    background: #f7f7f6;
  }
  &.hasOrder {
    &:after {
      opacity: 1;
      transform: translate(-50%, 100%) scale(1);
      animation: hasOrder 0.5s forwards;
    }
  }
}
@keyframes hasOrder {
  0% {
    transform: translate(-50%, 100%) scale(10);
  }
  100% {
    transform: translate(-50%, 100%) scale(1);
  }
}
.date-list__item-date {
  position: relative;
}
.holiday {
  .date-month,
  .date-day,
  .date-daytype {
    color: variables.$sunday;
  }
}
.saturday {
  .date-month,
  .date-day,
  .date-daytype {
    color: variables.$saturday;
  }
}
.date-month {
  font-size: 1rem;
  font-weight: 700; /* bold */
  margin-right: 0.25em;
  position: relative;
  top: -0.1em;
}
.date-day {
  font-size: 1.4rem;
  font-weight: 700; /* bold */
}
.date-daytype {
  font-size: 1.3rem;
  font-weight: 700; /* bold */
  letter-spacing: 0.1em;
}
#orderMenuWrap {
  position: relative;
  width: 100vw;
  overflow: hidden;
}
.orderMenuWrap__inner {
  width: 700vw;
  display: flex;
  transition: all 0.3s ease;
}
.orderMenu {
  padding-bottom: 90px;
  width: 100vw;
}
.shop-select {
  position: relative;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 0 30px;
}
.shop-select__label {
  @extend .f_body;
  margin-right: 10px;
  white-space: nowrap;
}
.select-wrap {
  position: relative;
  overflow: hidden;
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    border-color: variables.$txt-default transparent transparent transparent;
    position: absolute;
    left: 220px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
select {
  font-size: 1.6rem;
  transform: scale(0.81);
  color: variables.$txt-default;
  width: 300px;
  height: 53px;
  border: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  transform-origin: center left;
  box-sizing: border-box;
  padding: 0 15px;
}
</style>
