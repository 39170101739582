<template>
  <div class="shop">
    <div id="title-area">
      <div class="logo"><img alt="" src="../../assets/logo@2x.png" /></div>
      <span class="version">受発注管理システム イセヤ画面 version 1.0.0 </span>
    </div>
    <div class="login-form">
      <input type="text" v-model="loginId" placeholder="ID" />
      <input type="password" v-model="password" placeholder="パスワード" />
      <!-- <router-link> -->
      <div>{{ errorMessage }}</div>
      <btnS
        class="submit"
        btnTxt="ログイン"
        color="primary"
        @click="executeLogin"
      />
      <!-- </router-link> -->
      <span v-on:click="showForgotPass = !showForgotPass" class="forgotPass">
        パスワードを忘れた場合</span
      >
      <transition name="fade">
        <div class="forgotPassDialog" v-show="showForgotPass">
          <div class="forgotPassDialog-blk">
            info@sample.com までご連絡ください。
            <span
              class="forgotPassDialog-close"
              v-on:click="showForgotPass = !showForgotPass"
            >
              ×</span
            >
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#title-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media.max-screen(media.$breakpoint-sm) {
    padding: 40px 0 80px;
  }
  @include media.min-screen(media.$breakpoint-md) {
    padding: 100px 0 80px;
  }
}
.logo {
  width: 230px;
  height: 47px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.version {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.06em;
  line-height: 34px;
  text-align: center;
  color: #3b4043;
}
.login-form {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
input[type="text"],
input[type="password"] {
  width: 250px;
  height: 40px;
  border-radius: 3px;
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  border: 1px solid #ededed;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 33px;
  @include mixin.placeholderColor(#cbcbcb);
}
.submit {
  margin-top: 12px;
  margin-bottom: 65px;
}
.forgotPass {
  @extend .f_caption;
  text-decoration: underline;
  margin-left: auto;
  cursor: pointer;
}
.forgotPassDialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
}
.forgotPassDialog-blk {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 400px;
  background: #fff;
  border: solid 1px variables.$border-1;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 30px;
  @extend .f_body;
}
.forgotPassDialog-close {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: 50%;
  border: solid 1px #cbcbcb;
  background: #000;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
// @ is an alias to /src
import btnS from "@/components/btn/btnS";
import ApiClient from "@/module/api";

export default {
  name: "iseyaLogin",
  components: {
    btnS
  },
  data: function() {
    return {
      showForgotPass: false,
      loginId: "",
      password: "",
      errorMessage: ""
    };
  },
  methods: {
    async executeLogin() {
      const param = {
        loginid: this.loginId,
        password: this.password
      };
      const api = new ApiClient();
      const result = await api.post("/iseya/login", param, false);
      if (result.errors && result.errors.loginid) {
        this.errorMessage = result.errors.loginid.error;
        return;
      }
      this.$router.push("/iseya/home");
    }
  }
};
</script>
