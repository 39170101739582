var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Header'),_c('pageTitle',{attrs:{"txt":"通常発注 一覧","backBtn":_vm.backBtn,"backURL":"/iseya/home/"}}),_c('div',{attrs:{"id":"wrapper"}},[_c('ul',{staticClass:"list"},_vm._l((_vm.stores),function(store){return _c('router-link',{key:store.id,attrs:{"to":{
          path: '/iseya/order/detail/',
          query: {
            date: _vm.ymdFormat(_vm.date),
            shopID: store.id,
            shopName: store.name,
            tel: store.tel
          }
        }}},[_c('orderItem',{attrs:{"id":store.id,"date":_vm.ymdFormat(_vm.date),"name":store.name,"tel":store.tel,"period":_vm.startDate + '～' + _vm.endDate,"unread":store.is_read,"url":"/iseya/order/detail/"}})],1)}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }