<template>
  <div class="orderConfirm-singleDay">
    <div class="orderConfirm-meta">
      <span class="orderConfirm-date">
        {{ month }}月{{ date }}日（{{ thisWeekDay }}）
      </span>
      <div class="orderConfirm-time">
        <div v-if="orders[orderNum].thisDayTime == 'pm'">
          <btnToggle
            class="order-state__btn on primary"
            btnTxt="指定日PM"
            color=""
          />
        </div>
        <div v-if="orders[orderNum].thisDayTime == 'am'">
          <btnToggle
            class="order-state__btn on negative"
            btnTxt="翌日AM"
            color=""
          />
        </div>
      </div>
    </div>

    <ul class="orderConfirm-list">
      <li
        class="orderConfirm-list__item"
        v-for="product in orders[orderNum].products"
        :key="product.item_id"
      >
        <div class="orderConfirm-list__item-row-1">
          <span class="orderConfirm-list__item-name">
            <span class="label-deadline over" v-if="extra && vacuumPeriod <= 0">
              受付終了
            </span>
            <span
              class="label-deadline today"
              v-if="extra && vacuumPeriod == 1"
            >
              本日締切
            </span>
            {{ product.name }}
          </span>
          <span class="orderConfirm-list__item-amount">
            <span class="orderConfirm-list__item-amount__num">
              {{ product.quantity + product.add_quantity }}
            </span>
            {{ product.unit }}
          </span>
        </div>
        <div class="orderConfirm-list__item-row-2">
          <span class="orderMenu-txt">内 真空</span>
          <div class="orderMenu-amount">
            <input
              type="number"
              class="orderMenu-amount__num"
              @change="changeVacuumQuantity(product, $event)"
              :value="product.vacuumQuantity + product.addVacuumQuantity"
            />
            <span class="orderMenu-amount__unit">{{ product.unit }}</span>
          </div>
        </div>
        <div class="orderConfirm-list__item-row-3">
          <span
            v-on:click="
              vacuumMinus(product, {
                event: $event,
                id: product.id,
                orderNum: orderNum
              })
            "
            class="orderMenu-amount__btn orderMenu-amount__minus"
          >
          </span>
          <span
            v-on:click="
              vacuumPlus(product, {
                event: $event,
                id: product.id,
                orderNum: orderNum
              })
            "
            class="orderMenu-amount__btn orderMenu-amount__plus"
          >
          </span>
        </div>
      </li>
    </ul>
    <div class="orderConfirm-singleDay__total">
      <span class="orderConfirm-singleDay__total-price">
        合計
        <span class="orderConfirm-singleDay__total-num">
          {{ orders[orderNum].thisDaySubTotal }}
        </span>
        円
      </span>
      <span class="orderConfirm-singleDay__total-box">
        箱数
        <span class="orderConfirm-singleDay__total-num">
          {{ orders[orderNum].thisDayBoxQt }}
        </span>
        個
      </span>
    </div>
  </div>
</template>
<script>
// import { mapMutations } from "vuex";
import btnToggle from "@/components/btn/btnToggle";
export default {
  name: "orderConfirmSingleday",
  data: function() {
    return {
      isActive: true,
      today: "",
      extraPeriod: "", //追加発注の期限
      vacuumPeriod: "", //真空の期限 発送日の前日
      transferDays: 0 //配達日数 //shopの都道府県でtransferのdaysを検索
    };
  },
  props: {
    year: Number,
    month: Number,
    date: Number,
    day: Number,
    thisWeekDay: String,
    orderNum: Number,
    extra: Boolean
  },
  components: {
    btnToggle
  },
  computed: {
    orders: function() {
      return this.$store.state.shopOrder.data.order;
    },
    products: function() {
      return this.$store.state.products;
    }
  },
  mounted() {
    this.fixedDate();

    let thisDay = new Date(this.year, this.month - 1, this.date);
    // let weekday = thisDay.getDay();
    // this.thisWeekDay = ["日", "月", "火", "水", "木", "金", "土"][weekday];

    //今日の日付
    let getDate = new Date();
    let y = getDate.getFullYear();
    let m = getDate.getMonth();
    let d = getDate.getDate();
    this.today = new Date(y, m, d);
    this.today = new Date(2022, 1, 6);

    //追加発注の期限
    //到着日 - 今日の日付 - 配達日数 なか日 - 配達日数（基準日）
    this.extraPeriod =
      (thisDay - this.today) / 86400000 - this.transferDays - 1;
    //真空の期限
    //到着日 - 今日の日付 - 配達日数 なか日 - 配達日数（基準日）
    this.vacuumPeriod =
      (thisDay - this.today) / 86400000 - this.transferDays - 1;
  },
  methods: {
    // ...mapMutations(["vacuumPlus", "vacuumMinus"]),
    fixedDate() {
      let adjustOffset;
      let ua = navigator.userAgent;
      if (ua.indexOf("iPhone") > -1 && ua.indexOf("OS 15") > -1) {
        adjustOffset = 5;
      } else {
        adjustOffset = 0;
      }
      let dateObjArray = [];
      let dateObj = document.getElementsByClassName("orderConfirm-meta");

      for (let $i = 0; $i < dateObj.length; $i++) {
        dateObjArray.push(dateObj[$i].getBoundingClientRect().top);
      }

      window.addEventListener("scroll", function() {
        let scrolled = window.scrollY;

        for (let $i = 0; $i < dateObj.length; $i++) {
          dateObj[$i].classList.remove("is-fixed");
          if (dateObjArray[$i] < scrolled + adjustOffset) {
            dateObj[$i].classList.add("is-fixed");
          }
        }
      });
    },
    vacuumPlus(product) {
      product.addVacuumQuantity++;
      if (product.add_quantity < product.addVacuumQuantity) {
        product.addVacuumQuantity = product.add_quantity;
      }
    },
    vacuumMinus(product) {
      product.addVacuumQuantity--;
      if (product.addVacuumQuantity < 0) {
        product.addVacuumQuantity = 0;
      }
    },
    changeVacuumQuantity(product, event) {
      if (event) {
        product.addVacuumQuantity = parseInt(event.target.value, 10);
      }
      if (product.add_quantity < product.addVacuumQuantity) {
        product.addVacuumQuantity = product.add_quantity;
      }
      if (product.addVacuumQuantity < 0) {
        product.addVacuumQuantity = 0;
      }
    }
  }
};
</script>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

.orderConfirm-list {
  display: flex;
  flex-wrap: wrap;
}
.orderConfirm-list__item {
  width: 50%;
  height: 125px;
  border-bottom: solid 1px variables.$bg-gray-2;
}

.orderConfirm-list__item-row-1 {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
  height: 60px;
}

.orderConfirm-list__item-row-2 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
  height: 25px;
}

.orderConfirm-list__item-row-3 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #f7f7f6;
}

.orderConfirm-list__item-name {
  @extend .f_body;
}
.orderConfirm-list__item-amount {
  @extend .f_body;
  margin-left: auto;
}

.orderConfirm-singleDay {
  position: relative;
  box-sizing: border-box;
  padding-top: 60px;
}

.orderConfirm-meta {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 0 15px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #fcfcfc;
  z-index: 5;
  &.is-fixed {
    position: fixed;
  }
}

.orderConfirm-date {
  width: auto;

  display: block;
  @extend .f_pageTtl;
  box-sizing: border-box;
  margin-right: auto;
}

$value: "";
@for $i from 1 through 7 {
  .day#{$i} {
    .orderConfirm-date {
      z-index: #{$i};
    }
  }
}
.holiday {
  .orderConfirm-date {
    color: variables.$sunday;
  }
}
.saturday {
  .orderConfirm-date {
    color: variables.$saturday;
  }
}
.orderConfirm-singleDay__total {
  background: variables.$bg-gray-2;
  height: 60px;
  display: flex;
  align-items: center;
}
.orderConfirm-singleDay__total-price,
.orderConfirm-singleDay__total-box {
  width: 50%;
  box-sizing: border-box;
  padding: 0 15px;
  width: 50%;
  @extend .f_body;
}
.orderConfirm-singleDay__total-num {
  @extend .f_number;
  margin-left: 13px;
  margin-right: 1px;
}

.orderMenu-txt {
  color: #ababab;
  @extend .f_body;
}
.orderMenu-amount {
  display: flex;
  align-items: center;
  //position: absolute;
  //right: 135px;
  //top: -30%;
  //width: 100%;
  height: 40px;
}
.orderMenu-amount__num {
  color: #ababab;
  @extend .f_body;
  text-align: right;
  padding-right: 5px;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
}
.orderMenu-amount__unit {
  color: #ababab;
  @extend .f_body;
  white-space: nowrap;
}
.orderMenu-amount__btn {
  width: 40px;
  height: 40px;
  background: linear-gradient(#f4f4f4 0%, #ededed 100%);
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  &:before {
    content: "";
    width: 12px;
    height: 1px;
    background: variables.$scrollbar-back;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.orderMenu-amount__minus {
  border-right: solid 1px variables.$border-1;
}
.orderMenu-amount__plus {
  &:after {
    content: "";
    width: 1px;
    height: 12px;
    background: variables.$scrollbar-back;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.label-deadline {
  position: relative;

  z-index: 3;
  font-size: 11px;
  font-weight: 700; /* bold */
  color: #fff;

  width: 53px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.over {
    background: variables.$txt-default;
  }
  &.today {
    background: variables.$negative;
  }
}
</style>
