<template>
  <div>
    <Header />
    <pageTitle txt="発送登録" :backBtn="backBtn" />
    <div id="wrapper">
      <ul class="list">
        <li class="list-item" v-for="transfer in transfers" :key="transfer.id">
          <div class="list-item__data col-destination">
            {{ transfer.destination }}
          </div>
          <div class="list-item__data col-days">中{{ transfer.days }}日</div>
          <div class="list-item__data col-fee">{{ transfer.fee }}円</div>
          <div class="list-item__data col-btn">
            <div>
              <btnS
                class="edit"
                btnTxt="編集"
                color="positive1"
                @click="showEdit(transfer)"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div id="overlay" :class="{ visible: overlay }">
      <div id="edit" v-if="edit">
        <div class="dialog">
          <p class="dialog-ttl">発送情報編集</p>
          <div class="dialog-form">
            <div class="dialog-form__item name">
              <label>都道府県</label>
              <div>
                {{ targetTransfer.destination }}
              </div>
            </div>
            <div class="dialog-form__item days">
              <label>発送日数</label>
              中
              <input type="text" id="editDays" v-model="targetTransfer.days" />
              日
              <div>{{ errors.days }}</div>
            </div>
            <div class="dialog-form__item fee">
              <label>送料</label>
              <input type="text" id="editFee" v-model="targetTransfer.fee" /> 円
              <div>{{ errors.fee }}</div>
            </div>
          </div>
        </div>
        <div class="overlay-btn">
          <div @click="(edit = !edit), (overlay = !overlay)">
            <btnS btnTxt="戻る" color="back" />
          </div>
          <div>
            <btnL btnTxt="この内容で登録する" color="primary" @click="save" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#add,
#edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 35px;
  &.name {
    width: 100px;
  }

  &.days {
    width: 67px;
    input {
      width: 34px;
    }
  }
  &.fee {
    width: 85px;
    input {
      width: 65px;
    }
  }
  &.name,
  &.days {
    margin-right: 20px;
  }
  label {
    margin-bottom: 5px;
    display: block;
    height: 17px;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  width: 1281px;
  margin-left: auto;
  margin-right: auto;
  border-left: solid 1px variables.$bg-gray-2;
  @extend .f_caption;
  display: flex;
  flex-wrap: wrap;
  .small {
    font-size: 1rem;
  }
}

.list-item {
  display: flex;
  align-items: center;
  width: calc(100% / 3);
  height: 60px;
  box-sizing: border-box;
  border-bottom: solid 1px variables.$bg-gray-2;
  &:nth-of-type(6n - 2),
  &:nth-of-type(6n - 1),
  &:nth-of-type(6n) {
    background: variables.$bg-gray-1;
  }
}

.list-item__data {
  display: flex;
  align-items: center;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 15px;
  &.col-fee {
    justify-content: flex-end;
  }
}

.col-destination {
  width: 120px;
}

.col-days {
  width: 62px;
  padding: 0;
  justify-content: center;
}

.col-fee {
  width: 90px;
}

.col-btn {
  width: 150px;
  justify-content: center;
  flex-direction: row;
  box-sizing: border-box;
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL
  },
  data: function() {
    return {
      backBtn: true,
      edit: false,
      add: false,
      overlay: false,
      editId: "",
      transfers: [],
      targetTransfer: {},
      errors: {}
    };
  },
  async mounted() {
    this.transfers = await this.$store.getters.transfers;
  },
  methods: {
    showEdit(transfer) {
      this.targetTransfer = transfer;
      this.edit = true;
      this.overlay = true;
    },
    async save() {
      const param = {
        id: this.targetTransfer.id,
        days: this.targetTransfer.days,
        fee: this.targetTransfer.fee
      };
      const api = new ApiClient();
      const result = await api.post("/iseya/transfer/save", param);
      let isHit = false;
      let errors = {};
      Object.keys(result.errors).forEach(function(key) {
        errors[key] = result.errors[key][0];
        isHit = true;
      });
      if (isHit) {
        this.errors = errors;
        return;
      }
      this.edit = false;
      this.overlay = false;
    }
  }
};
</script>
