<template>
  <div class="pager">
    <div @click="toPrev" class="btn-prev">
      <btnS btnTxt="前へ" v-if="page.page > 1" color="neutral" />
    </div>
    <span v-for="(row, key2) in page.pages" :key="key2">
      <span class="pager-item pager-item__current" v-if="page.page == row">
        {{ row }}
      </span>
      <a class="pager-item" v-if="page.page != row" @click="movePage(row)">
        {{ row }}
      </a>
    </span>
    <div @click="toNext" class="btn-next">
      <btnS btnTxt="次へ" v-if="page.next == 1" color="neutral " />
    </div>
  </div>
</template>

<script>
import btnS from "@/components/btn/btnS";
export default {
  name: "pager",
  components: {
    btnS
  },
  props: {
    page: {
      type: Object
    }
  },
  methods: {
    toPrev() {
      this.$emit("movePage", this.page.page - 1);
    },
    toNext() {
      this.$emit("movePage", this.page.page + 1);
    },
    movePage(page) {
      this.$emit("movePage", page);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;

.pager {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pager-item {
  @extend .f_body;
  color: variables.$txt-default;
  margin: 0 1em;
}

.pager-item__current {
  color: variables.$txt-pale;
}

.btn-prev {
  margin-right: auto;
}

.btn-next {
  margin-left: auto;
}
</style>
