<template>
  <div>
    <Header />
    <pageTitle txt="お知らせ 一覧" :backBtn="backBtn" />
    <div id="wrapper">
      <ul class="list">
        <li class="list-head">
          <div class="list-head__item col-name">件名</div>
          <div class="list-head__item col-date">登録日</div>
          <div class="list-head__item col-content">本文</div>
          <div class="list-head__item col-btn">
            <div>
              <btnS
                class="add"
                btnTxt="新規登録"
                color="primary"
                @click="startNew()"
              />
            </div>
          </div>
        </li>
        <li class="list-item" v-for="(row, key) in list" :key="key">
          <div class="list-item__data col-name">{{ row.title }}</div>
          <div class="list-item__data col-date">{{ row.created }}</div>
          <div class="list-item__data col-content">{{ row.body }}</div>
          <div class="list-item__data col-btn">
            <div>
              <btnS
                class="edit"
                btnTxt="編集"
                color="positive1"
                @click="startEdit(row)"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="pager-wrap">
      <pager :page="page" @movePage="movePage" />
    </div>
    <div id="overlay" :class="{ visible: overlay }">
      <div id="add" v-if="add">
        <div class="dialog">
          <p class="dialog-ttl">新規お知らせ登録</p>
          <div class="dialog-form">
            <div class="dialog-form__item name">
              <label>件名</label>
              <input type="text" v-model="title" />
              <div>{{ errors.title }}</div>
            </div>
            <div class="dialog-form__item content">
              <label>本文</label>
              <textarea v-model="body"></textarea>
              <div>{{ errors.body }}</div>
            </div>
          </div>
        </div>
        <div class="overlay-btn">
          <div @click="(add = !add), (overlay = !overlay)">
            <btnS btnTxt="戻る" color="back" />
          </div>
          <div>
            <btnL
              btnTxt="この内容で登録する"
              color="primary"
              @click="executeSave"
            />
          </div>
        </div>
      </div>

      <div id="edit" v-if="edit">
        <div class="dialog">
          <p class="dialog-ttl">お知らせ編集</p>
          <div class="dialog-form">
            <div class="dialog-form__item name">
              <label>件名</label>
              <input type="text" v-model="title" />
              <div>{{ errors.title }}</div>
            </div>
            <div class="dialog-form__item content">
              <label>本文</label>
              <textarea v-model="body"></textarea>
              <div>{{ errors.body }}</div>
            </div>
          </div>
        </div>
        <div class="overlay-btn">
          <div @click="(edit = !edit), (overlay = !overlay)">
            <btnS btnTxt="戻る" color="back" />
          </div>
          <div>
            <btnL
              btnTxt="この内容で登録する"
              color="primary"
              @click="executeSave"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
#overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(59, 64, 67, 0.9);
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

#add,
#edit {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog {
  box-sizing: border-box;
  width: 800px;
  padding: 30px 50px 50px;
  background: #fff;
  margin: 0 auto 25px;
}

.dialog-ttl {
  @extend .f_pageTtl;
  margin-bottom: 15px;
  text-align: center;
}

.dialog-form {
  display: flex;
  flex-wrap: wrap;
}

.dialog-form__item {
  @extend .f_caption;
  margin-top: 15px;
  &.name,
  &.content {
    width: 100%;
  }
  label {
    margin-bottom: 5px;
    display: block;
    height: 17px;
  }
  input,
  select {
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
    width: 100%;
    height: 28px;
    padding: 0 12px;
  }
  .select {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;

      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: variables.$txt-default transparent transparent transparent;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%);
    }
  }
  textarea {
    width: 100%;
    height: 310px;
    padding: 12px;
    box-sizing: border-box;
    border: solid 1px variables.$bg-gray-3;
    border-radius: 3px;
    background: #fff;
  }
}

.overlay-btn {
  display: flex;
  justify-content: center;
  width: 800px;
  height: 60px;
  position: relative;
  margin: 0 auto;
  .buttonS {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
}

#wrapper {
  box-sizing: border-box;
  padding: 0 0 20px;
}

.list {
  box-sizing: border-box;
  width: 1281px;
  margin-left: auto;
  margin-right: auto;
  border-left: solid 1px variables.$bg-gray-2;
  border-bottom: solid 1px variables.$bg-gray-2;
  @extend .f_body;
}

.list-head {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
}

.list-head__item {
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
}

.list-item {
  display: flex;
  align-items: center;
  height: 60px;
  &:nth-of-type(even) {
    background: variables.$bg-gray-1;
  }
}

.list-item__data {
  display: flex;
  align-items: center;
  height: 100%;
  border-right: solid 1px variables.$bg-gray-2;
  box-sizing: border-box;
  padding: 0 15px;
}

.col-name {
  width: 320px;
}

.col-date {
  width: 160px;
}

.col-content {
  width: 580px;
}

.col-btn {
  width: 220px;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 30px;
}

.pager-wrap {
  width: 900px;
  margin: 0 auto;
}
</style>

<script>
import Header from "@/components/iseya/header";
import pageTitle from "@/components/iseya/pageTitle";
import btnS from "@/components/btn/btnS";
import btnL from "@/components/btn/btnL";
import pager from "@/components/common/pager";
import ApiClient from "@/module/api";

export default {
  name: "ShopHome",
  components: {
    Header,
    pageTitle,
    btnS,
    btnL,
    pager
  },
  data: function() {
    return {
      backBtn: true,
      edit: false,
      add: false,
      overlay: false,
      page: {},
      list: [],
      id: "",
      title: "",
      body: "",
      errors: {},
      pageNumber: 1
    };
  },
  created() {
    this._redraw();
  },
  methods: {
    startNew() {
      this.add = !this.add;
      this.overlay = !this.overlay;
      this.id = "";
      this.title = "";
      this.body = "";
    },
    startEdit(row) {
      this.edit = !this.edit;
      this.overlay = !this.overlay;
      this.id = row.id;
      this.title = row.title;
      this.body = row.body;
    },
    async executeSave() {
      this.errors = {};
      const param = {
        title: this.title,
        body: this.body
      };
      if (this.id) {
        param.id = this.id;
      }
      const api = new ApiClient();
      const result = await api.post("/iseya/news/save", param);
      let isHit = false;
      let errors = {};
      Object.keys(result.errors).forEach(function(key) {
        errors[key] = result.errors[key][0];
        isHit = true;
      });
      if (isHit) {
        this.errors = errors;
        return;
      }
      this.add = false;
      this.edit = false;
      this.overlay = false;
      this._redraw();
    },
    movePage(page) {
      this.pageNumber = page;
      this._redraw();
    },
    async _redraw() {
      const api = new ApiClient();
      const result = await api.get(`/iseya/news/list/${this.pageNumber}`);
      this.list = result.list;
      this.page = result.page;
    }
  }
};
</script>
