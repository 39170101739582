<template>
  <div class="order-state" v-if="order.length > 0">
    <div class="order-state__inner">
      <div class="order-state__weight">
        <span class="order-state__label">合計重量</span>
        <span class="order-state__num">
          <span class="order-state__value">
            {{ order[activeDay].thisDayWeight / 1000 }}
          </span>
          <span class="order-state__unit">kg</span>
        </span>
      </div>
      <div class="order-state__box">
        <span class="order-state__label">予想箱数</span>
        <span class="order-state__num">
          <span class="order-state__value">
            {{ order[activeDay].thisDayBoxQt }}
          </span>
          <span class="order-state__unit">個</span>
        </span>
      </div>
      <span class="order-state__destination">納品先：{{ address }}</span>
      <a @click="jump">
        <btnS
          class="order-state__btn"
          btnTxt="発注内容を確認"
          color="primary"
        />
      </a>
    </div>
  </div>
</template>

<script>
import btnS from "@/components/btn/btnS";
export default {
  name: "orderState",
  props: {
    activeDay: Number,
    jumpTo: String,
    address: String
  },
  components: {
    btnS
  },
  computed: {
    order: function() {
      return this.$store.state.shopOrder.data.order;
    }
  },
  methods: {
    jump: function() {
      this.$router
        .push({
          name: this.jumpTo,
          params: {
            address: this.address
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@use '@/css/utility/_colors' as colors;
@use '@/css/utility/_mixin' as mixin;
@use '@/css/utility/_media' as media;
@use '@/css/utility/_font' as font;
@use '@/css/utility/_variables' as variables;
.order-state {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 90px;
  //background: variables.$bg-gray-2;
  background: transparent;
  backdrop-filter: blur(5px);
  --webkit-backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.01);
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 15px 0 25px;
  transition: all 0.5s ease;
}
.order-state__inner {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  position: relative;
}
.order-state__weight {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 15px;
}
.order-state__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  margin-right: auto;
}
.order-state__label {
  @extend .f_caption;
}
.order-state__destination {
  @extend .f_caption;
  position: absolute;
  left: 0px;
  bottom: 10px;
}
.order-state__num {
  position: relative;
  top: -3px;
}
.order-state__value {
  @extend .f_number;
  margin: 0 5px;
}
.order-state__unit {
  @extend .f_body;
}
.order-state__btn {
  margin-left: auto;
}
</style>
